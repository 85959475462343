import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { GetTokenFunc } from "../utils/GetToken";

export const useGetUserProfile = () => {
  return useQuery({
    queryKey: ["userProfile"],
    queryFn: async () => {
      return await axios.get("api/v1/user/get-self", {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

//
export const useUpdateUserProfile = () => {
  return useMutation(async (data) => {
    return await axios.put("api/v1/user/update-profile", data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
    // Assuming your API returns some data
  });
};

export const useGetBatchList = () => {
  return useQuery({
    queryKey: ["batch-list"],
    queryFn: async () => {
      return await axios.get("api/v1/batch", {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useCreateBatch = () => {
  return useMutation({
    mutationKey: ["create-batch"],
    mutationFn: async (data) => {
      return await axios.post("api/v1/batch", data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

// export const useUpdateBatch = () => {
//   return useMutation(async (id) => {
//     return await axios.put(`api/v1/batch/${id}`,{}, {
//       headers: {
//         Authorization: `Bearer ${GetTokenFunc()}`,
//       },
//     });
//     // Assuming your API returns some data
//   });
// };

export const useUpdateBatch = () => {
  return useMutation({
    mutationKey: ["update-batch"],
    mutationFn: async (data) => {
      try {
        console.log("idishere", data);
        const response = await axios.put(
          `api/v1/batch/${data.id}`,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error updating batch:", error);
        throw error;
      }
    },
  });
};

export const useDeleteBatch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }) => {
      return await axios.delete("api/v1/batch/" + id, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useDeleteBatchQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axios.post(`api/v1/batch/delete_blog_questions`, data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const GetQuestionsById = (id) => {
  return useQuery({
    queryKey: ["batch-list", id],
    enabled: !!id,
    queryFn: async () => {
      return await axios.get(`api/v1/batch/get_question_by_id/${id}`, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useUpdateIsCredited = () => {
  return useMutation(async () => {
    return await axios.post(
      "api/v1/user/update-credit-flag",
      {},
      {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      }
    );
    // Assuming your API returns some data
  });
};
