import {
  Box,
  Paper,
  Stack,
  Typography,
  Input,
  InputAdornment,
} from '@mui/material';
import axios from 'axios';
import cookie from 'js-cookie';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import pluscircle from '../../Assets/OuterImg/UploadIcon.svg';
import { GetTokenFunc } from '../../utils/GetToken';
import Header from '../HeaderUi/Header';
// import HomeBg from "../../Assets/OuterImg/HomeBg.svg";
import HomeBg from '../../Assets/OuterImg/HomeBG1.svg';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaDiscord } from 'react-icons/fa';
import { Ring, Waveform } from '@uiball/loaders';
import * as Styles from '../../Common/Styles.js';
import searchimg from '../../Assets/mainPage/searchimg1.svg';
import OpinionComponent from './OpinionComponent.jsx';
import BlogsComponent from './BlogsComponents.jsx';
import Articles from './Articles.jsx';
import Footer from './Footer.jsx';
import WheelImg from '../../Assets/mainPage/wheel.svg';
import { motion } from 'framer-motion';
import leftArrrow from '../../Assets/Homeimg/LeftArrow.svg';
import rightArrow from '../../Assets/Homeimg/Rightarrow.svg';
import botharrow from '../../Assets/Homeimg/Both.svg';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import paradoc from '../../Assets/Homeimg/paradoc.png';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import News from './News.jsx';
const NewHome = () => {
  const navigate = useNavigate();
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />
      <Paper elevation={0} sx={mainHomePaper}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: {
                xl: '50px',
                lg: '50px',
                md: '20px',
                sm: '20px',
                xs: '20px',
              },
            }}
          >
            <Fade left duration={1000}>
              <Box
                component={'img'}
                src={leftArrrow}
                sx={{
                  width: {
                    xl: 'auto',
                    lg: 'auto',
                    md: 'auto',
                    sm: 'auto',
                    xs: '90px',
                  },
                }}
              />
            </Fade>
            <Fade top duration={1000}>
              <Box component={'span'} sx={antText}>
                Para
              </Box>
            </Fade>
            <Fade right duration={1000}>
              <Box
                component={'img'}
                src={rightArrow}
                sx={{
                  width: {
                    xl: 'auto',
                    lg: 'auto',
                    md: 'auto',
                    sm: 'auto',
                    xs: '90px',
                  },
                }}
              />
            </Fade>
          </Box>
          <Zoom duration={1000}>
            <Typography sx={subText}>
              {/* Join millions of students, researchers and professionals to
              instantly answer questions and understand Legal research with AI. */}
              Your AI Paralegal - for Indian case law research, document
              analysis and drafting
            </Typography>
          </Zoom>
          {/* <label
            htmlFor="fileInput"
            style={{
              color: '#445FD2',
              fontWeight: 600,
              margin: '0 5px',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                padding: '10px',
                background: '#F9F9FC',
                boxShadow: 2,
                mt: 4,
                borderRadius: {
                  xl: '25px',
                  lg: '25px',
                  md: '16px',
                  sm: '10px',
                  xs: '10px',
                },
              }}
            >
              <Box
                onClick={() => {
                  navigate('/login');
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                sx={fileUploadBox}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifycontent: 'center',
                    alignItmes: 'center',
                  }}
                  onDrop={handleDrop}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifycontent: 'center',
                      alignItmes: 'center',
                      gap: 1,
                      mt: { xl: 5, lg: 4, md: 3, sm: 1, xs: 1 },
                    }}
                    onDrop={handleDrop}
                  >
                    {loading ? (
                      <Stack>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Ring
                            size={60}
                            lineWeight={2}
                            speed={2}
                            color="#A1A1A1"
                          />
                        </Box>
                        <Typography sx={uploadText}>
                          Uploading your file
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography sx={{ MulishFontTextStyle }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <Box
                            component={'img'}
                            src={pluscircle}
                            alt=""
                            sx={{
                              height: 'auto',
                              width: {
                                xl: '35px',
                                lg: '35px',
                                md: '20px',
                                sm: '16px',
                                xs: '12px',
                              },
                            }}
                          />
                        </Box>{' '}
                        <Typography sx={uploadText}>Drop PDF here</Typography>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </label> */}
          {/* <Box mt={2}>
            <Input
              id="standard-basic"
              variant="outlined"
              disableUnderline
              placeholder="Search here"
              sx={{
                ...Styles.InputFieldDes,
                border: '1px solid #68858C',
                color: '#9E9E9E',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                boxShadow: '10px 10px 60px #bebebe',
                height: {
                  xl: '50px',
                  lg: '50px',
                  md: '50px',
                  sm: '35px',
                  xs: '35px',
                },
                padding: '0px 20px',
                borderRadius: '10px',
                width: {
                  xl: '900px',
                  lg: '900px',
                  md: '620px',
                  sm: '450px',
                  xs: '300px',
                },
              }}
              //   onFocus={handleInputFocusInput}
              //   onBlur={handleInputBlurInput}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component="img"
                    src={searchimg}
                    sx={{ height: 'auto', width: '22px', paddingLeft: '10px' }}
                  />
                </InputAdornment>
              }
              inputProps={{
                style: {
                  color: '#9E9E9E',
                  fontSize: '18px',
                  padding: '7px',
                  paddingLeft: '10px', // Adjust the left padding to make room for the icon
                },
              }}
            />
          </Box> */}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: {
              xl: '100px',
              lg: '100px',
              md: '80px',
              sm: '80px',
              xs: '50px',
            },
            width: {
              xl: '500px',
              lg: '500px',
              md: '400px',
              sm: '300px',
              xs: '300px',
            },
            display: 'flex',
            // background: "green",
            padding: '20px',
            // justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              // background: "red",
              flex: '1 1 50%',
              paddingLeft: '25px',
            }}
          >
            <Flip top duration={2000}>
              {/* <Typography sx={bottomText}>PARA Sors</Typography> */}
              <Box
                component={'img'}
                src={parasorc}
                alt=""
                sx={{
                  height: 'auto',
                  maxWidth: '70%',
                }}
              />
            </Flip>
            <Fade left duration={1000}>
              <Typography sx={bottomSubText}>
                provides updates on legal news and create summaries of court
                judgments
              </Typography>
            </Fade>
          </Box>
          <Box component={'img'} src={botharrow} />
          <Box
            sx={{
              // background: "yellow",
              flex: '1 1 50%',
              paddingLeft: '35px',
            }}
          >
            <Flip top duration={2000}>
              <Box
                component={'img'}
                src={paradoc}
                alt=""
                sx={{
                  height: 'auto',
                  maxWidth: {
                    xl: '140px',
                    lg: '140px',
                    md: '120px',
                    sm: '70px',
                    xs: '70px',
                  },
                }}
              />
            </Flip>
            <Fade right duration={1000}>
              <Typography sx={bottomSubText}>
                analyses and manages legal documents .
              </Typography>
            </Fade>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: 200,
            zIndex: -1,
          }}
        >
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 3, repeat: 0, ease: 'easeInOut' }}
            // transition={{ duration: 3, repeat: 0, ease: "easeIn" }}
          >
            <Box
              component={'img'}
              src={WheelImg}
              sx={{
                height: 'auto',
                width: {
                  xl: '380px',
                  lg: '380px',
                  md: '380px',
                  sm: '300px',
                  xs: '300px',
                },
              }}
            />
          </motion.div>
        </Box>
      </Paper>
      <News />
      <Articles />
      <OpinionComponent />
      <BlogsComponent />
      <Footer />
    </div>
  );
};

export default NewHome;
const MainTextStyle = {
  fontFamily: 'Poppins',
  fontSize: {
    xl: '5rem',
    lg: '5rem',
    md: '4rem',
    sm: '4rem',
    xs: '2.5rem',
  },
  fontStyle: 'normal',
  lineHeight: 1.1,
};
const askText = {
  ...MainTextStyle,
  color: '#030229',
  textAlign: 'center',
  fontWeight: 100,
};
const antText = {
  ...MainTextStyle,
  color: '#081930',
  fontWeight: 900,
  fontFamily: 'Manrope',
  textTransform: 'capitalize',
};
const LawText = {
  ...MainTextStyle,
  background:
    'linear-gradient(123deg, #4BF7F3 10.18%, #AD9DF0 20.81%, #FF51E0 82.15%, #FF9943 91.35%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 900,
};
const subText = {
  color: '#6D6D6D',
  textAlign: 'center',
  fontFamily: 'Manrope',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: {
    xl: '1rem',
    lg: '1rem',
    md: '0.8rem',
    sm: '0.8rem',
    xs: '0.5rem',
  },
  width: { xl: '80%', lg: '80%', md: '80%', sm: '80%', xs: '80%' },
  mx: 'auto',
  mt: 4,
};
const MulishFontTextStyle = {
  fontFamily: 'poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#676767',
  textAlign: 'center',
  lineHeight: '12px',
};
const uploadText = {
  color: 'rgba(0, 0, 0, 0.88)',
  fontFamily: 'Poppins',
  fontSize: { xl: '12px', lg: '10px', md: '8px', sm: '6px', xs: '6px' },
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'center',
};
const fileUploadBox = {
  background: 'red',
  // border: "2px solid #009FFC",
  border: '1px dashed #D9D9D9',
  '&:focus': {
    outline: 'none',
  },
  '&:focus-within': {
    border: '2px dashed ##009FFC',
  },
  bgcolor: 'background.paper',
  // boxShadow: 5,
  borderRadius: {
    xl: '20px',
    lg: '20px',
    md: '16px',
    sm: '10px',
    xs: '10px',
  },
  gap: 0.5,
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
  width: {
    xl: '1000px',
    lg: '1000px',
    md: '700px',
    sm: '400px',
    xs: '220px',
  },
  height: {
    xl: '130px',
    lg: '130px',
    md: '100px',
    sm: '70px',
    xs: '50px',
  },
};
const mainHomePaper = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
  flexDirection: 'column',
  backgroundImage: `url(${HomeBg})`,
  backgroundSize: 'cover',
  objectFit: 'contain',
  position: 'relative',
  zIndex: 0,
};
const bottomText = {
  fontFamily: 'Manrope',
  fontSize: { xl: '28px', lg: '28px', md: '16px', sm: '14px', xs: '12px' },
  fontWeight: 700,
};
const bottomSubText = {
  fontFamily: 'Manrope',
  fontSize: { xl: '11px', lg: '11px', md: '10px', sm: '8px', xs: '6px' },
  fontWeight: 600,
  color: '#000000',
  width: '80%',
};
