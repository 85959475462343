import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Grid, Tabs, Tab, Button } from '@mui/material';
import sheild from '../../Assets/mainPage/sheild.svg';
import HeaderPhone from '../HeaderUi/HeaderPhone';
import Header from '../HeaderUi/Header';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import paradoc from '../../Assets/Homeimg/paradoc.png';
import parasearch from '../../Assets/Homeimg/parasearch.png';
import * as Styles from '../../Common/Styles.js';
import Footer from '../HomeChat/Footer.jsx';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
const OurServices = () => {
  return (
    <>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              zIndex: 1,
              position: 'absolute',
            }}
          >
            <Header />
            <Box sx={{ mt: 12 }}>
              <Fade top duration={1000}>
                <Typography sx={Styles.headHeading}> Our Services</Typography>
              </Fade>
              <Zoom duration={1000}>
                <Typography sx={Styles.subheading}>
                  Transforming legal hurdles into smooth pathways
                </Typography>
              </Zoom>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            mt: 5,
          }}
        >
          <Box sx={headBox}>
            <Box sx={mainBox}>
              <Fade top duration={1000}>
                <Box
                  component={'img'}
                  src={parasorc}
                  alt=""
                  sx={{
                    height: 'auto',
                    width: {
                      xl: '200px',
                      lg: '200px',
                      md: '200px',
                      sm: '120px',
                      xs: '120px',
                    },
                  }}
                />
              </Fade>
            </Box>
            <Typography sx={subtext}>
              ParaSors leverages AI to provide e-journal updates and generate
              original headnotes for Indian court judgments. Summarizes legal
              decisions with precision, highlighting key points and
              implications.Keeps users informed with daily updates on the latest
              legal developments, ensuring relevance and timeliness.
            </Typography>
          </Box>
          <Box sx={headBox}>
            <Box sx={mainBox}>
              <Fade top duration={1000}>
                {/* <Box component={'img'} src={sheild} alt="" sx={imgStyle} /> */}
                <Typography sx={headtext}>
                  Para Draft
                  <Box
                    component={'span'}
                    sx={{
                      fontFamily: 'DM Sans',
                      fontSize: {
                        xl: '12px',
                        lg: '12px',
                        md: '12px',
                        sm: '8px',
                        xs: '8px',
                      },
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 1)',
                    }}
                  >
                    {' '}
                    (coming soon...)
                  </Box>
                </Typography>
              </Fade>
            </Box>
            <Typography sx={subtext}>
              Paradraft focuses on enhancing legal drafting processes, offering
              tools and templates tailored for Indian legal practices.
              Streamlines the creation of statutory forms and legal documents,
              ensuring efficiency and accuracy. Incorporates legal precedents
              into drafting processes, facilitating the development of nuanced
              legal arguments and strategies.
            </Typography>
          </Box>
        </Box>
        <Zoom duration={2000}>
          <Box sx={backText}>WHAT WE DO</Box>
        </Zoom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            mt: -2,
          }}
        >
          <Box sx={{ ...headBox, mt: 6 }}>
            <Box sx={mainBox}>
              <Fade top duration={1000}>
                <Box
                  component={'img'}
                  src={parasearch}
                  alt=""
                  sx={{
                    height: 'auto',
                    width: {
                      xl: '200px',
                      lg: '200px',
                      md: '200px',
                      sm: '120px',
                      xs: '120px',
                    },
                  }}
                />
              </Fade>
            </Box>
            <Typography sx={subtext}>
              ParaSearch's flagship product is a cutting-edge legal research
              assistant built upon an advanced Language Learning Model (LLM).
              This model is meticulously trained on a wide array of Indian legal
              terminologies, precedents, and understandings, offering
              exceptional expertise in Indian legal matters. Expertly curated
              and regularly updated, ensuring the most accurate and relevant
              legal information. Exhaustive collection from the Supreme Court
              and all High Courts, providing a comprehensive resource for case
              law research.
            </Typography>
          </Box>
          <Box sx={headBox}>
            <Box sx={mainBox}>
              <Fade top duration={1000}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    flexDirection: {
                      xl: 'row',
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}
                >
                  <Box
                    component={'img'}
                    src={paradoc}
                    alt=""
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '200px',
                        lg: '200px',
                        md: '200px',
                        sm: '100px',
                        xs: '100px',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      ...subtext,
                      ml: -2,
                      mt: { xl: 1.5, lg: 1.5, md: 1.5, sm: 0, xs: 0 },
                    }}
                  >
                    (coming soon...)
                  </Typography>
                </Box>
              </Fade>
            </Box>
            <Typography sx={subtext}>
              Paradoc specializes in the analysis and management of legal
              documents within the ParaSearch ecosystem. Automates analysis of
              legal documents, extracting key insights and information. Upload
              and Management: Facilitates seamless uploading and organization of
              legal documents for efficient access and retrieval.
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Footer />
    </>
  );
};

export default OurServices;

const mainBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  gap: '10px',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
};

const headtext = {
  fontFamily: 'DM Sans',
  fontSize: { xl: '28px', lg: '28px', md: '24px', sm: '16px', xs: '16px' },
  fontWeight: 700,
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 1)',
};

const subtext = {
  fontFamily: 'DM Sans',
  fontSize: { xl: '13px', lg: '13px', md: '10px', sm: '8px', xs: '8px' },
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 1)',
  //   width: '50%',
  //   mx: 'auto',
};

const headBox = {
  width: { xl: '30%', lg: '30%', md: '40%', sm: '40%', xs: '40%' },
  mx: 'auto',
};

const backText = {
  mt: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  fontFamily: 'DM Sans',
  fontSize: { xl: '115px', lg: '115px', md: '100px', sm: '50px', xs: '50px' },
  fontWeight: 700,
  textAlign: 'center',
  color: 'rgba(247, 249, 251, 1)',
};

const imgStyle = {
  height: 'auto',
  maxWidth: { xl: '100%', lg: '100%', md: '100%', sm: '15px', xs: '15px' },
};
