import { Box, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import cookie from 'js-cookie';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import pluscircle from '../../Assets/OuterImg/UploadIcon.svg';
import { GetTokenFunc } from '../../utils/GetToken';
import Header from '../HeaderUi/Header';
import HomeBg from '../../Assets/OuterImg/HomeBg.svg';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaDiscord } from 'react-icons/fa';
import { Ring, Waveform } from '@uiball/loaders';
const HomePage = () => {
  const navigate = useNavigate();
  const handleDragOver = (event) => {
    return;
    navigate('/login');
    event.preventDefault();
  };
  const [loading, setIsLoading] = useState(false);
  const uploadFile = async (files) => {
    setIsLoading(true);
    if (files.length === 1) {
      uploadSingleFile(files[0]);
    } else {
      uploadMultipleFile(files);
    }
  };
  const handleDrop = (event) => {
    return navigate('/login');
    uploadFile(event.dataTransfer.files);
    event.preventDefault();
  };

  const uploadMultipleFile = async (event) => {
    const files = event;
    if (files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      await axios
        .post('api/v1/file/multiple-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${GetTokenFunc()}`,
          },
        })
        .then((res) => {
          navigate('/mainchat/' + res.data.data._id);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };
  const uploadSingleFile = async (event) => {
    const file = event;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await axios
        .post('api/v1/file/single-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${GetTokenFunc()}`,
          },
        })
        .then((res) => {
          navigate('/mainchat/' + res.data.data._id);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const getToken = () => {
    return cookie.get('Bearer');
  };

  return (
    <>
      <Header />
      <Paper elevation={0} sx={mainHomePaper}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography sx={askText}>
            ASK <br />
            <Box component={'span'} sx={antText}>
              Anything{' '}
              <Box component={'span'} sx={LawText}>
                Law
              </Box>
            </Box>{' '}
          </Typography>

          <label
            htmlFor="fileInput"
            style={{
              color: '#445FD2',
              fontWeight: 600,
              margin: '0 5px',
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                padding: '10px',
                background: '#F9F9FC',
                boxShadow: 2,
                mt: 4,
                borderRadius: {
                  xl: '25px',
                  lg: '25px',
                  md: '16px',
                  sm: '10px',
                  xs: '10px',
                },
              }}
            >
              <Box
                onClick={() => {
                  // if (!getToken()) {
                  navigate('/login');
                  // }
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                sx={fileUploadBox}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifycontent: 'center',
                    alignItmes: 'center',
                  }}
                  onDrop={handleDrop}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifycontent: 'center',
                      alignItmes: 'center',
                      gap: 1,
                      mt: { xl: 5, lg: 4, md: 3, sm: 1, xs: 1 },
                    }}
                    onDrop={handleDrop}
                  >
                    {loading ? (
                      <Stack>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* <BarLoader color="#445FD2" /> */}
                          <Ring
                            size={60}
                            lineWeight={2}
                            speed={2}
                            color="#A1A1A1"
                          />
                        </Box>
                        <Typography sx={uploadText}>
                          Uploading your file
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography sx={{ MulishFontTextStyle }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <Box
                            component={'img'}
                            src={pluscircle}
                            alt=""
                            sx={{
                              height: 'auto',
                              width: {
                                xl: '35px',
                                lg: '35px',
                                md: '20px',
                                sm: '16px',
                                xs: '12px',
                              },
                            }}
                          />
                          {/* <input
                            type="file"
                            accept=".pdf"
                            id="fileInput"
                            style={{ display: "none" }}
                            multiple
                            onChange={(e) => {
                              uploadFile(e.target.files);
                            }}
                          /> */}
                        </Box>{' '}
                        <Typography sx={uploadText}>Drop PDF here</Typography>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </label>

          <Typography sx={subText}>
            Join millions of students, researchers and professionals to
            instantly answer questions and understand research with AI
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              position: 'absolute',
              bottom: 20,
            }}
          >
            <TwitterIcon
              sx={{
                height: '15px',
                cursor: 'pointer',
              }}
            />
            <FaDiscord
              style={{
                height: '15px',
                cursor: 'pointer',
              }}
            />
            <LinkedInIcon
              sx={{
                height: '15px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default HomePage;
const MainTextStyle = {
  fontFamily: 'Poppins',
  fontSize: {
    xl: '5rem',
    lg: '5rem',
    md: '4rem',
    sm: '4rem',
    xs: '2.5rem',
  },
  fontStyle: 'normal',
  lineHeight: 1.1,
};
const askText = {
  ...MainTextStyle,
  color: '#030229',
  textAlign: 'center',
  fontWeight: 100,
};
const antText = {
  ...MainTextStyle,
  color: '#030229',
  fontWeight: 900,
  textTransform: 'capitalize',
};
const LawText = {
  ...MainTextStyle,
  background:
    'linear-gradient(123deg, #4BF7F3 10.18%, #AD9DF0 20.81%, #FF51E0 82.15%, #FF9943 91.35%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 900,
};
const subText = {
  color: '#4F4F4F',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: {
    xl: '1rem',
    lg: '1rem',
    md: '0.8rem',
    sm: '0.8rem',
    xs: '0.5rem',
  },
  width: { xl: '70%', lg: '70%', md: '80%', sm: '80%', xs: '80%' },
  mx: 'auto',
  mt: 4,
};
const MulishFontTextStyle = {
  fontFamily: 'poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#676767',
  textAlign: 'center',
  lineHeight: '12px',
};
const uploadText = {
  color: 'rgba(0, 0, 0, 0.88)',
  fontFamily: 'Poppins',
  fontSize: { xl: '12px', lg: '10px', md: '8px', sm: '6px', xs: '6px' },
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'center',
};
const fileUploadBox = {
  background: 'red',
  // border: "2px solid #009FFC",
  border: '1px dashed #D9D9D9',
  '&:focus': {
    outline: 'none',
  },
  '&:focus-within': {
    border: '2px dashed ##009FFC',
  },
  bgcolor: 'background.paper',
  // boxShadow: 5,
  borderRadius: {
    xl: '20px',
    lg: '20px',
    md: '16px',
    sm: '10px',
    xs: '10px',
  },
  gap: 0.5,
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
  width: {
    xl: '1000px',
    lg: '1000px',
    md: '700px',
    sm: '400px',
    xs: '220px',
  },
  height: {
    xl: '130px',
    lg: '130px',
    md: '100px',
    sm: '70px',
    xs: '50px',
  },
};
const mainHomePaper = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
  flexDirection: 'column',
  backgroundImage: `url(${HomeBg})`,
  backgroundSize: 'cover',
  objectFit: 'contain',
  position: 'relative',
  zIndex: 0,
};
