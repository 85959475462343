import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import Header from '../HeaderUi/Header';
import Footer from '../HomeChat/Footer.jsx';
import * as Styles from '../../Common/Styles.js';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
const PrivacyPolicy = () => {
  return (
    <>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }}>
                Privacy Policy
              </Typography>
            </Fade>
          </Box>
        </Box>
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            mt: 2,
          }}
        >
          <Fade bottom duration={2000}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              PRIVACY POLICY
            </Typography>
            <Typography paragraph>
              We make it available on our home page and at every point where
              personally identifiable information may be requested. If you
              already are a registered user to access{' '}
              <a href="http://www.parasors.in">http://www.parasors.in</a> this
              privacy policy shall co-exist with the terms of Parasors License
              Agreement that also governs in case of any conflict amongst User
              License and Privacy Terms of its other proprietary products,
              associates, affiliates and subsidiaries.
            </Typography>
            <Typography paragraph>
              The Web Site contains links to other Web Sites operated by third
              parties. Parasors.in is not responsible for the privacy practices
              or policies of such third party Web Sites. We do not share any of
              the individual personal information you provide Parasors.in with
              the Sites to which parasors.in links, although we may share
              aggregate data with such Web sites (such as how many people use
              our Site).
            </Typography>
            <Typography paragraph>
              Please check with those sites to determine their privacy policy.
            </Typography>
            <Typography paragraph>
              Please keep in mind that whenever you voluntarily disclose
              personal information online - for example through e-mail,
              discussion lists, or elsewhere - that information can be collected
              and used by others. In short, if you post personal information
              online that is accessible to the public, you may receive
              unsolicited messages from other parties in return.
            </Typography>
            <Typography paragraph>
              Ultimately, you are solely responsible for maintaining the secrecy
              of your personal information. Please be careful and responsible
              whenever you're online.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              THE INFORMATION WE COLLECT
            </Typography>
            <Typography paragraph>
              The Website parasors.in is not set up to automatically collect
              personally identifiable information from each visitor to the
              Website. It does recognize the home server of visitors, but not
              e-mail addresses. For example, we can tell which Internet Service
              Provider our visitors use, but not the names, addresses or other
              information about our visitors that would allow us to identify a
              particular visitor to our Website. This information is used only
              for internal purposes by our technical support staff.
            </Typography>
            <Typography paragraph>
              The Web Site does track certain information about the visits to
              the Web Site parasors.in. For example, we compile statistics that
              show the numbers and frequency of visitors to the Web Site and its
              individual pages and time spent by each visitor on each page.
              These aggregated statistics are generally used internally to
              improve the Web Site and for product development and marketing
              purposes. Those aggregated statistics may also be provided to
              advertisers and other third parties, but again, the statistics
              contain no personal information and cannot be used to gather such
              information.
            </Typography>
            <Typography paragraph>
              In a few areas on the Web Site parasors.in, we ask you to provide
              information that will enable us to verify whether you are entitled
              to access and use certain information, materials and services
              available from the Web Site parasors.in. This information may also
              be used to enable us to enhance your Site visit, to assist you
              with customer service or technical support issues, or to follow up
              with you after your visit, or to otherwise support your
              relationship with parasors.in. It is completely optional for you
              to participate. However, failure to participate may mean that you
              cannot access and use certain information, materials and services.
            </Typography>
            <Typography paragraph>
              For example, we request information from you when you:
            </Typography>
            <ol>
              <li>
                <Typography paragraph>
                  Log-on to certain areas of the Web Site parasors.in where you
                  may be prompted to provide us with your Log In Name and
                  Password as a condition to gaining access to certain
                  information, materials or services.
                </Typography>
              </li>
              <li>
                <Typography paragraph>
                  Register or sign-up to use a service.
                </Typography>
              </li>
              <li>
                <Typography paragraph>
                  Subscribe to a newsletter or desire to be added to our mailing
                  lists for other products and services.
                </Typography>
              </li>
              <li>
                <Typography paragraph>Place an order.</Typography>
              </li>
              <li>
                <Typography paragraph>
                  Provide feedback in an online survey.
                </Typography>
              </li>
            </ol>
            <Typography paragraph>
              In each of the instances above, we typically ask for your name,
              e-mail address, phone number, address, area of practice
              information, product preference information, Log In Name and
              Password, as well as, other similar personal information that is
              needed to register or subscribe you to services or offers. On
              occasion, we may ask for additional information to enable us to
              provide you with access to and use of certain information,
              materials and services. In the case of newsletters or mailing
              lists, you will be able to "unsubscribe" to these mailers at any
              time.
            </Typography>
            <Typography paragraph>
              Wherever parasors.in collects personal information we make an
              effort to include a link to this Privacy Policy on that page.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              HOW WE USE INFORMATION
            </Typography>
            <Typography paragraph>
              parasors.in uses your personal information for specific purposes
              only.
            </Typography>
            <Typography paragraph>
              The personal information you provide to us when using the Web
              Site, such as your name, postal, e-mail address or telephone
              number will be kept confidential and used to support your
              relationship with parasors.in, to notify you of special offers,
              updated information and new products and services from
              parasors.in, or offers from third parties that we think may be of
              interest to you. Agents or contractors of parasors.in who are
              given access to your personal information will be required to keep
              the information confidential and not use it for any other purpose
              other than to carry out the services they are performing for
              parasors.in.
            </Typography>
            <Typography paragraph>
              parasors.in may enhance or merge your information collected at its
              Web Site with data from third parties for purposes of marketing
              products or services to you.
            </Typography>
            <Typography paragraph>
              With respect to network advertising companies (companies that
              manage and provide advertising for numerous unrelated companies),
              to the extent that parasors.in utilizes such advertising companies
              to provide advertisements on the Web Site, parasors.in may provide
              them with your Sign in Name and any demographic information about
              you that we collect. Those advertising companies may combine that
              data with non-personally identifiable data collected by the
              advertising company from your computer, solely for the purpose of
              delivering on our Web Site, advertisements that are targeted to
              you.
            </Typography>
            <Typography paragraph>
              Circumstances may arise where we are required to disclose your
              personal information to third parties for purposes other than to
              support your customer relationship with parasors.in, such as in
              connection with a corporate divestiture or dissolution where we
              sell all or a portion of our business or assets (including our
              associated customer lists containing your personal information),
              or if disclosure is required by law or is pertinent to judicial or
              governmental investigations or proceedings.
            </Typography>
            <Typography paragraph>
              We will send you information about our various products and
              services, or other products and services we feel may be of
              interest to you. Only parasors.in (or agents or contractors
              working on behalf of parasors.in and under confidentiality
              agreements) will send you these direct mailings.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              YOUR ACCEPTANCE OF THESE TERMS
            </Typography>
            <Typography paragraph>
              By using this Web Site, you signify your assent to the parasors.in
              Privacy Policy. If you do not agree to this policy, please do not
              use our Web Site. Your continued use of the parasors.in Web Site
              following the posting of changes to these terms will mean you
              accept those changes. You can send e-mail to us with any further
              questions.
            </Typography>
          </Fade>
        </Box>
        <Footer />
      </Paper>
    </>
  );
};

export default PrivacyPolicy;
