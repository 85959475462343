export const newsArticles = [
  {
    id: 1,
    header: "Rules for a new dawn for the Indian legal industry",
    content:
      "As India increasingly becomes a leading hub for cross-border commerce, the globalisation of the Indian legal industry becomes inevitable.",
    author: "Ronak Patel",
    date: "July 14, 2022",
  },
  {
    id: 2,
    header:
      "UAE Scores convicted in sham mass trial violating fundamental legal principles",
    content:
      "Update: In the evening, local time on 10 July, the UAE's government news agency issued information on the trial revealing that six of The UAE authorities have convicted scores of individuals in a mass trial that violated fundamental legal principles and fair trial guarantees, Human Rights Watch said today.",
    author: "Rahul Goswami",
    date: "Aug 04, 2022",
  },
  {
    id: 3,
    header: "Rules for a new dawn for the Indian legal industry.",
    content:
      "As India increasingly becomes a leading hub for cross-border commerce, the globalisation of the Indian legal industry becomes inevitable.",
    author: "Aditya Chaurasia",
    date: "Dec 29, 2023",
  },
  {
    id: 4,
    header:
      "Supreme Court stays Allahabad high court's decision to scrap UP Madrassa law",
    content:
      "India News: NEW DELHI: The Supreme Court on Friday stayed Allahabad high court's order striking down UP Board of Madrassa Education Act.",
    author: "Sandeep Bhatt",
    date: "Feb 30, 2022",
  },
  {
    id: 5,
    header:
      "Delhi court dismisses Kejriwal’s application seeking to increase legal meetings",
    content:
      "The Delhi chief minister had filed an application before the court seeking to increase his legal meetings from twice a week to five times a",
    author: "Aakash Kumble",
    date: "Oct 22, 2020",
  },
  {
    id: 6,
    header:
      "U.S. Supreme Court Chief Justice urges ‘caution’ as AI reshapes legal field.",
    content:
      "Chief Justice Roberts urges caution & humility in legal field as AI transforms work; AI has potential to increase access to justice",
    author: "Samay Raina",
    date: "Sep 05, 2023",
  },
  {
    id: 7,
    header:
      "CONSTITUTIONALITY OF SUB-CLASSIFYING SCHEDULED CASTES FOR RESERVATION PURPOSES",
    content:
      "The case of The State of Punjab and Others vs. Davinder Singh and Others deals with the constitutionality of sub-classifying Scheduled Castes for reservations. The Punjab legislature&#8217;s law, which reserved 12.5% of the Scheduled Castes quota for Balmikis and Mazhbi Sikhs, was struck down by the High Court based on the Supreme Court&#8217;s ruling in E.V. Chinnaiah. The Supreme Court is reconsidering this decision, focusing on whether such sub-classifications can be constitutionally valid if supported by empirical evidence. The matter has been referred to a larger bench for a more thorough examination.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 8,
    header:
      "BIGAMY AND SENTENCING: CHALLENGING ACQUITTAL AND REVERSAL OF CONVICTION",
    content:
      "The appellant, challenged  which was later reversed by the High Court, resulting in their conviction under Section 494 IPC for bigamy. The High Court imposed a lenient sentence of imprisonment till rising of the court and a fine, which the appellant contends is inadequate. The Supreme Court is now considering whether the High Court showed undue leniency and if the sentence requires enhancement, emphasizing the importance of proportionality in sentencing serious offences.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 9,
    header:
      "SUPREME COURT REVIEWS LEGALITY OF ARVIND KEJRIWAL'S ARREST UNDER PMLA IN EXCISE POLICY SCAM",
    content:
      "Delhi CM Arvind Kejriwal has appealed against the Delhi High Court's judgment rejecting his writ petition challenging his arrest by the DOE under the PMLA, 2002. The case revolves around the alleged criminal conspiracy in the formulation of the excise policy for liquor sales in Delhi. The CBI and DoE investigations revealed undue pecuniary gain to public servants and the involvement of Arvind Kejriwal as a key conspirator. The Supreme Court's judgment focused on the legality of his arrest and the principles of proportionality and necessity, with Arvind Kejriwal remaining under custody as legal proceedings continue.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 10,
    header:
      "MUMBAI TEEN SUFFERING FROM INTERNET GAMING DISORDER ALLOWED TO RETAKE EXAMS",
    content:
      "The Bombay High Court has permitted a 19-year-old boy to retake his class 12 improvement exam, citing his struggles with anxiety, depression, and Internet Gaming Disorder. Background Student: 19-year-old boy from an eastern suburb of Mumbai. Academic History: Consistently scored 85-93% until class 11. Issue: During March 2023 class 12 exams, he scored 319.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 11,
    header: "ROLE OF OPPOSITION POLITICAL PARTIES IN DEMOCRATIC SET UP",
    content:
      "Democracy is when the indigent and not the men of property, are the rulers” –Aristotle. The Opposition Political Parties are the second largest Parties after the ruling parties who have formed the government, in the Parliament and the State legislature of India. The opposition political parties should fulfill the mandatory criteria of the 10.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 12,
    header: "CONVICTION FOR MURDER AND ATTEMPTED MURDER BY POLICE GUARD",
    content:
      "The Supreme Court of India examined a criminal appeal by Surender Singh, contesting his conviction for murder and attempted murder within a police station. Following an intense argument, Singh, a police guard, opened fire, killing one person and injuring another. The defense claimed grave and sudden provocation, but the court concluded that the evidence—such as the weapon used and the multiple shots fired—indicated premeditated murder. The conviction was affirmed, highlighting the necessity of timely cross-examinations for fair trials.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 13,
    header: "SC PLEA FOR PROBE INTO HATHRAS STAMPEDE",
    content:
      "A Public Interest Litigation (PIL) has been filed in the Supreme Court, seeking a high-level committee led by a retired Supreme Court judge to investigate the stampede incident at a satsang in Hathras, resulting in 121 deaths. Background Incident: Stampede at a satsang of Bhole Baba(Narayan Sakar Hari) in Hathras, Uttar Pradesh.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 14,
    header:
      "ALLAHABAD HIGH COURT WARNS UNCHECKED CONVERSIONS MAY MAKE MAJORITY A MINORITY",
    content:
      "The Allahabad High Court expressed concerns over the rising trend of religious conversions in India, emphasizing that unchecked conversions could lead to the majority Hindu population becoming a minority. The court dismissed a bail plea in a case involving alleged forced conversions to Christianity. Background Accused: Kailash, a resident of Maudaha in Hamirpur district.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 15,
    header: "NEW CRIMINAL LAW BILLS",
    content:
      "The Bharatiya Nyaya Sanhita, 2023 (BNS), the Bharatiya Nagarik Suraksha Sanhita, 2023 (BNSS), and the Bharatiya Sakshya Bill, 2023 (BSB) aim to modernize India's legal framework by replacing the Indian Penal Code, 1860 (IPC), the Code of Criminal Procedure, 1973 (CrPC), and the Indian Evidence Act, 1872 (IEA). These bills represent a significant effort",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
  {
    id: 16,
    header:
      "BOMBAY HIGH COURT PERMITS 24-WEEK PREGNANCY TERMINATION FOR CANCER PATIENT",
    content:
      "The Bombay High Court has granted permission for a 24-year-old woman diagnosed with stage four pancreatic cancer to terminate her 24-week pregnancy. This decision comes after medical advice indicated that her cancer treatment could not proceed while she remained pregnant. Background A young woman undergoing treatment for advanced pancreatic cancer in Mumbai faced a.",
    author: "Nancy Sharma",
    date: "Aug 10, 2024",
  },
];
