import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import Markdown from 'react-markdown';
const AnimatedText = ({
  text,
  speed = 100,
  lineByLine = false,
  setLastMessageId,
}) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    const words = lineByLine ? text.split('\n') : text.split(' ');
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex < words.length) {
        setDisplayedText((prev) =>
          lineByLine
            ? prev +
              (currentIndex > 0 ? '\n' : '') +
              (words[currentIndex] ?? '')
            : prev + (currentIndex > 0 ? ' ' : '') + (words[currentIndex] ?? '')
        );
        currentIndex++;
      } else {
        clearInterval(intervalId);
        setLastMessageId(null);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed, lineByLine]);

  return (
    // <p
    //   component="div"
    //   sx={{
    //     fontFamily: 'Manrope',
    //     fontSize: '13px',
    //     fontStyle: 'normal',
    //     fontWeight: '400',
    //     letterSpacing: 0.9,
    //     whiteSpace: 'pre-wrap',
    //   }}
    // >
    <Markdown
      components={{
        pre: 'p',
        code: 'p',
      }}
    >
      {displayedText}
    </Markdown>
    // </p>
  );
};

export default AnimatedText;
