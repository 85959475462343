import React from 'react';
import * as Styles from '../../Common/Styles.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { Paper, Box, Typography, Button } from '@mui/material';
import BlogViewer from '../CommonToast/Blogview';
// import paralogo from '../../Assets/Header/paralogo.svg';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ViewBlog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Paper elevation={0} sx={{ height: '100%', width: '90%', mx: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // width: '100%',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            sx={{ color: 'primary.main', cursor: 'pointer', mt: 2 }}
          />
          <Box
            component={'img'}
            src={parasorc}
            alt=""
            sx={{ height: 'auto', maxWidth: '150px', mt: 2 }}
          />
        </Box>
        <Box mt={3}>
          <h3 style={{ textAlign: 'center' }}>{location?.state?.title}</h3>
        </Box>
        <Box>
          <BlogViewer>{location?.state?.['content']}</BlogViewer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                ...Styles.gridheaderText,
                color: 'rgba(62, 50, 50, 1)',
                textAlign: 'left',
                fontWeight: 700,
              }}
            >
              {location?.state?.creator}
            </Typography>
            <Box
              component={'img'}
              src={parasorc}
              alt=""
              sx={{ height: 'auto', maxWidth: '100px', mt: 1, mb: 2 }}
            />
          </Box>

          <Typography
            sx={{
              ...Styles.gridheaderText,
              color: 'rgba(62, 50, 50, 1)',
              textAlign: 'left',
              fontWeight: 700,
            }}
          >
            {new Date(location?.state?.pubDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ViewBlog;
