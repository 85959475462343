import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Button, TextField } from '@mui/material';
import watchicon from '../../Assets/mainPage/watchicon.svg';
import HeaderPhone from '../HeaderUi/HeaderPhone';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import Footer from '../HomeChat/Footer.jsx';
import Fade from 'react-reveal/Fade';
const Termsandcondition = () => {
  return (
    <>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              zIndex: 1,
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }}>
                Terms & Condition
              </Typography>
            </Fade>
          </Box>
        </Box>

        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            mt: 2,
            textAlign: 'justify',
          }}
        >
          <Fade bottom duration={2000}>
            <Typography paragraph>
              Terms and conditions for the use of the website{' '}
              <a href="http://www.parasors.in"> www.parasors.in </a> and/or any
              other websites under the company Unada LexiLegal AI India Private
              Limited
            </Typography>
            <a href="http://www.parasors.in"> www.parasors.in</a>
            <Typography paragraph mt={2}>
              This document is an electronic record in terms of the Information
              Technology Act 2000 and rules made thereunder as applicable and
              the amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures. This document is
              published in accordance with the provisions of Rule 3 (1) of the
              Information Technology (Intermediaries guidelines) Rules 2011 that
              require publishing the rules and regulations privacy policy and
              user agreement for access or usage of{' '}
              <a href="http://www.parasors.in"> www.parasors.in </a> and
              associated websites.
            </Typography>
            <Typography paragraph>
              Your use of a Parasors website including{' '}
              <a href="http://www.parasors.in"> www.parasors.in </a>{' '}
              (hereinafter referred to as the “Website") and its related sites,
              services, Apps, and tools is governed by the terms and conditions
              as contained in this Agreement applicable to the Website and for
              other websites of Unada LexiLegal AI India Private Limited and our
              subsidiaries and international affiliates. This User Agreement for
              the Website shall come into effect immediately on your acceptance
              of terms and conditions and/ or first login onto{' '}
              <a href="http://www.parasors.in"> www.parasors.in </a> It shall
              continue to remain in force till such time you have a valid
              subscription to the website. ("User Agreement"). Parasors.in is an
              internet-based portal owned and operated by Unada LexiLegal AI
              India Pvt. Ltd.; a company incorporated under the Companies Act
              1956 having its registered office at 550 Iscon Emporio Jodhpur
              Char Rasta Ahmedabad - 380015.
            </Typography>
            <Typography paragraph>
              For the purpose of this User Agreement, Registered User / Guest
              User and wherever the context so require '“you” “your” shall mean
              any natural or legal person who is accessing the Website, its
              contents, and using the services offered on or through the Website
              and has agreed to become a member of the Website by providing
              Registration Data (as defined hereinafter) while registering on
              the Website as Registered User / Guest User using the computer
              systems of the Website and accepted this electronic version /
              electronic record of the User Agreement and has allocated
              himself/herself a unique identification user name ("User ID" and
              "Password") to become Registered User or have been identified as
              Guest User by providing phone number and email id. Further
              reference to “we” “us” “our” and “Parasors” shall mean Unada
              LexiLegal AI India Pvt. Ltd. (Parasors.in) and includes its
              associates, affiliates, and subsidiary(ies). If you already are a
              registered user to access
              <a href="http://www.parasors.in"> www.parasors.in </a>and also an
              authorized user of its any other proprietary product(s) this
              Parasors License Agreement shall coexist with their user license
              terms and govern in case of any conflict amongst them.
            </Typography>
            <Typography paragraph>
              The use of the Website is offered to you conditioned on your
              ‘Acceptance’ of all the terms conditions and notices contained in
              this User Agreement. Upon “Acceptance” this User Agreement shall
              be effective and binding upon you along with any amendments made
              by Parasors at its sole discretion and posted on the Website and
              you shall not claim invalidity of this User Agreement merely on
              the grounds that this agreement is being concluded electronically.
              For the aforesaid purposes 'Acceptance' shall mean your
              affirmative action in clicking on the 'check box' and on the
              'continue button' as provided on the registration page or while
              transacting as Guest User or any act which reflects your use of
              the Website or the services provided by the Website or any such
              other actions that imply your acceptance. You hereby further agree
              that this User Agreement is being concluded and executed at
              Ahmedabad, Gujarat.
            </Typography>
            <Typography paragraph>
              Your use of the Website implies that you agree with the terms of
              the User Agreement. If you do not agree or are not willing to be
              bound by the terms and conditions of this User Agreement and Rules
              and Policies as displayed on the Website please do not click on
              the "check box " and/or on the "continue" button and do not seek
              to obtain access to or otherwise use the Website.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              1. DEFINITIONS
            </Typography>
            <Typography paragraph>
              1.1. The term “provider” including all its synonyms and would mean
              “Parasors.in” and Unada LexiLegal AI India Private Limited having
              its registered office at 550 Iscon Emporio Jodhpur Char Rasta
              Ahmedabad - 380015 (Hereinafter termed as “Parasors”). The term
              would also include any agent(s) who have been so authorized by
              Parasors to act on their behalf.
            </Typography>
            <Typography paragraph>
              1.2. The term “authorized user” would include any person whether
              an individual or a legal entity who has subscribed to the services
              of Parasors and to whom the access is restricted by the use of a
              sign-in user name and a password. The user name and password are
              either allotted by Parasors or chosen by the user or agreed upon
              by Parasors. It is made abundantly clear that only the authorized
              user has the right to access the services so offered by Parasors.
            </Typography>
            <Typography paragraph>
              1.3. For the purposes of this subscriber agreement, any person who
              does not have a legal or a contractual right to access the
              services but does so will fall within the definition of an
              “unauthorized user” and will be subject to the terms and
              conditions and expressly so with respect to respecting the
              intellectual property rights of the provider and abiding by
              licensing terms and conditions.
            </Typography>
            <Typography paragraph>
              1.4. The term “User” would include both the authorized and
              unauthorized user(s).
            </Typography>
            <Typography paragraph>
              1.5. The terms “service” or “services” would mean to include the
              interactive online information service offered by Parasors on the
              internet through which the user may access judgments, statutes,
              rules, and procedures, etc. the terms would include to mean the
              search tools through which the user can search through the hosted
              databases and information using a number of search tools that are
              present with a selection matching their search criteria. Users
              then select one or more of the items presented to view the full
              document/record
            </Typography>
            <Typography paragraph>
              1.6. The term “organization” would be used to define any company,
              partnership, firm, institute, trust, or any such entity which has
              a legal personality but is not a natural person.
            </Typography>
            <Typography paragraph>
              1.7. The term “Downloaded Data” means insubstantial portions of
              the Data downloaded and temporarily stored to a storage device
              under Subscriber’s exclusive control.
            </Typography>
            <Typography paragraph>
              1.8. The term “Order Form” means any order form attached to this
              Agreement either in print or online setting out the Data and
              Features including subscription plan available to Subscriber and
              the price charged thereof.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              2. WEBSITE LICENSE
            </Typography>
            <Typography paragraph>
              2.1. The services so offered by Parasors are subject to the user
              agreeing to all the terms and conditions of the license.
              Notwithstanding any / all clauses the terms shall be applicable
              mutatis mutandis on the subscriber during the subsistence of the
              subscription period (which includes the period of renewed
              subscription).
            </Typography>
            <Typography paragraph>
              2.2. Parasors grants the user a non-exclusive, non-transferable,
              revocable limited license to access and use for research purposes
              the online services and materials from time to time made
              available.
            </Typography>
            <Typography paragraph>
              2.2a. The right to electronically display materials retrieved from
              the online services to no more than one person at a time. Parasors
              Sign-in does not allow simultaneous access on a non-IP plan.
            </Typography>
            <Typography variant="body1" paragraph>
              2.3. The license includes the right to download and temporarily
              store insubstantial portions of data ('Downloaded Data") to a
              storage device under subscriber's exclusive control.
            </Typography>
            <Typography variant="body1" paragraph>
              (i) To display internally such downloaded data
            </Typography>
            <Typography variant="body1" paragraph>
              (ii) To quote and excerpt from such downloaded data (appropriately
              cited &Credited) by electronic cutting & pasting or other means in
              subscriber's own workproducts.
            </Typography>
            <Typography variant="body1" paragraph>
              (iii) To create printouts of substantial portions of data for
              internal use and for distribution to third parties if such third
              parties agrees not to distribute the printouts and on
              non-commercial basis.
            </Typography>
            <Typography paragraph>
              2.4. Parasors reserves the right to terminate this license at any
              time for any reason in case any of the terms are revoked.
            </Typography>
            <Typography paragraph>
              2.5. No material/content downloaded from the Website of Parasors
              shall be reproduced, transmitted, or stored in any other Website
              nor shall any of its pages be disseminated either in electronic or
              non-electronic form or included in any public or private
              electronic retrieval system or service without the prior written
              permission of Parasors.
            </Typography>
            <Typography paragraph>
              2.6. It is made abundantly clear that Parasors does not have
              absolute control over the contents posted on the website and hence
              does not guarantee the accuracy, quality, or integrity of such
              content. The views expressed in the article section of the website
              are those of the respective authors and not of Parasors. Any
              illegal or offensive content posted on the site if detected should
              be brought to Parasors’s attention for immediate action. Parasors
              will not be responsible in any manner for any defamatory or
              contemptuous matter posted herein.
            </Typography>
            <Typography paragraph>
              2.7. All rights are not expressly granted herein are reserved.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              3. SUBSCRIPTION
            </Typography>
            <Typography variant="body1" paragraph>
              3.1. In order to become and remain an authorized user a party has
              to:
              <br />
              3.1.1. Complete the order form <br />
              3.1.2. Pay the subscription in advance fee applicable as mentioned
              in the order form; <br /> 3.1.3. Pay for usage of information/data
              according to the Parasors product pricing policy. The subscription
              fee applicable and the product price information price card are
              available from Parasors on request.
            </Typography>
            <Typography paragraph>
              3.2. After payment of the subscription fee applicable and after
              reading this entire document and the Terms and Conditions and
              Copyright Notice contained in it the intending subscriber should
              confirm that he/it accepts all the terms and conditions herein and
              agrees to be bound by them and to observe them strictly by
              clicking the “I accept” button on the program and/or by submitting
              to Parasors the registration form included in this document. It is
              made clear that by clicking on the “I ACCEPT” button the party
              gets all the rights and liabilities as it would have got in case
              of physically signed any document.
            </Typography>
            <Typography paragraph>
              3.3. Parasors reserves the right to alter the subscription rates
              without any prior notice.
            </Typography>
            <Typography paragraph>
              3.4. In case the subscription fee is not paid by the subscriber
              then Parasors shall have the right to deny access to its services
              to the subscriber.
            </Typography>
            <Typography paragraph>
              3.5. The subscriber shall be responsible for keeping secure the
              user identity and password required to access the Online Service.
              For the purpose of this Agreement, all actions performed by any
              person using the identity and password allotted to the subscriber
              shall be deemed to have been committed by the subscriber and the
              subscriber shall be liable for the same. The subscriber shall
              ensure that the password is kept confidential and the subscriber
              shall ensure that the password should not be shared with
              unauthorized users. Parasors reserves the right to terminate the
              license at any time if it is found that the subscriber has been
              sharing the password with any unauthorized user.
            </Typography>
            <Typography paragraph>
              3.6. An organization may subscribe and obtain multiple license
              access for multiple users who are valid constituents of that
              organization. For this purpose, paid employees of any
              organization, patrons of a library, and enrolled students, faculty
              and other staff members of an educational institution are
              considered “valid” constituents. Provided however, that the
              organization shall bear the sole responsibility for ensuring the
              compliance with and observance of the terms and conditions herein
              entered by its constituents and shall be liable for actions of its
              constituents in relation to the Online Service and its contents.
            </Typography>
            <Typography paragraph>
              3.7. The subscriber accepts that by the action of viewing and/ or
              downloading the information from the Parasors website, it has
              performed the action of using the Parasors website.
            </Typography>
            <Typography paragraph>
              3.8. In addition to the subscription fee and usage charges, the
              subscriber is liable to pay any and all taxes, duties and levies
              which may be applicable as per law or regulation and which
              Parasors may apply to the total billing of the subscriber.
            </Typography>
            <Typography paragraph>
              3.9. A detailed statement of the subscription and usage charges,
              for subscribers under Transactional Pricing, which the subscriber
              may incur, will be available for the subscriber to view and verify
              on the information service. This shall be treated as final and
              binding unless the subscriber shall bring to the notice of
              Parasors any discrepancies within seven days of the charge having
              been debited to it/him after which period any such charges shall
              be deemed to have been debited and accepted by the subscriber.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              4. OBLIGATIONS OF THE SUBSCRIBER
            </Typography>
            <Typography paragraph>
              4.1. The Subscriber agrees that the data/information retrieved
              from Parasors are the exclusive property of Parasors.
            </Typography>
            <Typography paragraph>
              4.2. On expiry or termination of subscription/renewal subscription
              the Subscriber agrees to return all data/material relating to the
              service to Parasors or to destroy such material.
            </Typography>
            <Typography paragraph>
              4.3. The Subscriber agrees to be responsible for the maintenance
              of all computer hardware and other equipment required for
              accessing the Service and the cost of all telecommunications
              charges required for accessing the service.
            </Typography>
            <Typography paragraph>
              4.4. The Subscriber agrees that any attempt to copy, reverse
              engineer, modify, decompile, disassemble or tamper with the system
              or software will render the subscriber liable for damages. The
              onus to prove that it was not done by the subscriber will be on
              the subscriber.
            </Typography>
            <Typography paragraph>
              4.5. The Subscriber agrees that each sign-on and password must be
              used by a single user and are not transferable.
            </Typography>
            <Typography paragraph>
              4.6. The Subscriber agrees that it will promptly notify Parasors
              if it becomes aware of any unauthorized use of its Sign on and
              Password.
            </Typography>
            <Typography paragraph>
              4.7. The Subscriber agrees to respect and abide by all the
              intellectual property laws and license terms and conditions.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              5. MAINTENANCE AND SYSTEM OUTAGE
            </Typography>
            <Typography paragraph>
              5.1. Parasors will make all reasonable efforts to provide
              uninterrupted access to the service. However, the subscriber
              accepts that Parasors does not warrant that the service will be
              uninterrupted or error-free. In addition, Parasors reserves the
              right to suspend the service temporarily at any time for
              operational reasons (e.g. maintenance or upgrades).
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              6. WARRANTIES AND INDEMNITIES
            </Typography>
            <Typography paragraph>
              6.1. Parasors warrants that it has the right to license the
              subscriber to use the service as described in this agreement.
              Except as expressly set out in this agreement Parasors makes no
              representations or warranties whatsoever with respect to the
              service or its content.
            </Typography>
            <Typography paragraph>
              6.2. Parasors specifically excludes and disclaims to the fullest
              extent permitted by the law any and all implied or statutory
              warranties including, without limitation, any implied warranties
              of merchantability or fitness for a particular purpose.
            </Typography>
            <Typography paragraph>
              6.3. Parasors does not guarantee nor warrant that the use of the
              service will be free from infection or viruses or that the
              information obtained by the subscriber using the service will be
              free from spam or other contaminants.
            </Typography>
            <Typography paragraph>
              6.4. In no event shall Parasors be liable for any direct,
              indirect, incidental, punitive, or consequential damages of any
              kind whatsoever with respect to the service, the content or the
              products or services listed or advertised on the site. Parasors’s
              entire liability shall not exceed the total amount paid by the
              subscriber to Parasors during the one month immediately preceding
              the date of the subscriber’s claim.
            </Typography>
            <Typography paragraph>
              6.5. Parasors shall not be liable for any loss or damages caused
              directly or indirectly including without limitation loss of
              profits or special damages arising out of the use of the website
              or any content herein.
            </Typography>
            <Typography variant="body1" paragraph>
              6.6. Parasors shall not be liable or responsible, whether under
              law or equity, for any delays, defaults or interruptions in the
              performance of the Service.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              7. INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <Typography variant="body1" paragraph>
              7.1. Except as expressly provided in the Terms & Conditions of Use
              of the Web Site Parasors, nothing contained herein shall be
              construed as conferring any license or right, by implication,
              estoppels or otherwise, under copyright or other intellectual
              property rights.
            </Typography>
            <Typography variant="body1" paragraph>
              7.2. The user agrees that copyrights, trademarks, patents, designs
              and other proprietary rights and laws protect the Web Site. Any
              rights not expressly granted herein are reserved.
            </Typography>
            <Typography variant="body1" paragraph>
              7.3. The user is also cautioned against any conduct on his part
              that infringes or purports to infringe the copyright or other
              proprietary rights or laws. The user shall not, under any
              circumstances whatsoever, use the printouts of the material
              available on this Web Site for any purpose that violates the
              copyright or any other proprietary rights of Parasors. The user
              shall not remove the copyright notices or other notices from the
              printouts of material taken from the Web Site.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              8. COPYRIGHT LAWS
            </Typography>
            <Typography variant="body1" paragraph>
              8.1. Content or information available on the Web Site Parasors is
              protected by the Copyright Act 1957 and is subject to Parasors's
              Copyright.
            </Typography>
            <Typography variant="body1" paragraph>
              8.2. Certain information and data made available by Parasors are
              the property of the content provider's and are identified as such.
              Parasors has been licensed by the content providers to store,
              catalogue and distribute this information to its subscribers. The
              information and data are protected by copyright and other
              intellectual property laws and no such right is deemed to have
              been transmitted by this arrangement to the user. As described
              elsewhere, the subscriber has obtained only the limited right to
              use, in the manner set out earlier, the information provided
              hereunder.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              9. LIMITATIONS ON USE
            </Typography>
            <Typography variant="body1" paragraph>
              9.1. The Content on the Web Site is for personal use only and not
              for commercial exploitation.
            </Typography>
            <Typography variant="body1" paragraph>
              9.2. You may not decompile, reverse engineer, disassemble, rent,
              lease, loan, sell, sub-license, or create derivative works from
              the Web Site or the Content. Nor may you use any network
              monitoring or discovery software to determine the Site
              architecture, or extract information about usage or users.
            </Typography>
            <Typography variant="body1" paragraph>
              9.3. You may not use any robot, spider, other automatic device, or
              manual process to monitor or copy our Web Site or the Content
              without Parasors's prior written permission.
            </Typography>
            <Typography variant="body1" paragraph>
              9.4. You may not alter, add, amend, copy, modify, reproduce,
              republish, distribute, display, or transmit for commercial,
              non-profit or public purposes all or any portion of the Web Site,
              except to the extent permitted above.
            </Typography>
            <Typography variant="body1" paragraph>
              9.5. You may not use or otherwise export or re-export the Web Site
              or any portion thereof, the Content or any software available, on
              or through the Web Site in violation of the export control laws
              and regulations of India. Any unauthorized use of the Web Site or
              its Content is prohibited.
            </Typography>
            <Typography variant="body1" paragraph>
              9.6. Combine the whole or any part of the Data with any other
              software, data or material.
            </Typography>
            <Typography variant="body1" paragraph>
              9.7. Subscriber may use Data cached in Subscriber's local disk
              drive solely in support of its use of the Service.
            </Typography>
            <Typography variant="body1" paragraph>
              9.8. Certain software used by Subscriber may not be capable of
              supporting the Service, and the performance of the Service will
              vary with the hardware on which it is used.
            </Typography>
            <Typography variant="body1" paragraph>
              9.9. Downloaded Data shall not be stored or used in an archival
              database or other searchable database except as expressly
              permitted by this Agreement.
            </Typography>
            <Typography variant="body1" paragraph>
              9.10. Subscriber undertakes to use its reasonable endeavors to
              ensure that the Service shall not be accessed or used by third
              parties other than those entitled to do so by virtue of this
              Subscriber Agreement. In the case of IP based Access, the
              subscriber shall, prior to commencement of subscription, provide
              the list of users (including the location/ branches, or as the
              case may be) who will be referred to as 'Authorized Users' under
              this subscription agreement.
            </Typography>
            <Typography variant="body1" paragraph>
              9.11. Subscriber shall use its reasonable endeavors to keep any
              Downloaded Data secure and to prevent any third party duplicating
              or otherwise reproducing in whole or in part Downloaded Data or
              any part thereof other than for the exercise of the rights granted
              by this Agreement, and shall use its reasonable endeavors to
              prevent whether by act or omission such duplication or
              reproduction except as permitted by the terms of this Agreement.
            </Typography>
            <Typography variant="body1" paragraph>
              9.12. The text of all the judgments provided on the site are
              computer generated. The authenticity, correctness and preciseness
              of the text of the judgments must be verified from the certified
              copy of the judgment.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              10. TERMINATION
            </Typography>
            <Typography variant="body1" paragraph>
              10.1. The services so offered by Parasors, are subject to the user
              agreeing to all the terms and conditions of the license. In case
              any of the terms are revoked, Parasors is entitled to terminate
              this subscription & discontinue the subscriber's right to access
              the Service. However Parasors shall not be liable to make any
              refunds in the event of subscriber indulging into activities
              strictly refrained from.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              11. CONSEQUENCES OF TERMINATION
            </Typography>
            <Typography variant="body1" paragraph>
              11.1. The warranties and indemnities, the provisions in relation
              to the payment of money due to Parasors and the restrictions on
              the rights of the subscriber in relation to the use of the
              Database, contained herein shall survive the termination or expiry
              of this subscription.
            </Typography>
            <Typography variant="body1" paragraph>
              11.2. Upon termination, the user must destroy all materials
              obtained from the Web works from and distribute such materials or
              incorporate such materials into any form, medium, or technology
              now known or later developed throughout Site.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              12. LICENSE OF YOUR CONTENT TO PROVIDER
            </Typography>
            <Typography variant="body1" paragraph>
              12.1. By uploading Content to submitting any materials for use on
              the Web Site, you grant (or warrant that the owner of such rights
              has expressly granted) us a perpetual, royalty-free, irrevocable,
              nonexclusive right and license to use, reproduce, modify, adapt,
              publish, translate, create derivative the universe.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              13. ERRORS AND CORRECTIONS
            </Typography>{' '}
            <Typography variant="body1" paragraph>
              13.1. Parasors makes reasonable effort to ensure that the
              information provided on the Web Site is accurate but does not
              represent or warrant that the information available on or through
              the Web Site will be correct, accurate, reliable, uninterrupted,
              or timely.
            </Typography>
            <Typography variant="body1" paragraph>
              13.2. Parasors does not represent or warrant that the Web Site
              will be error free, free of viruses or other harmful components,
              or that the defects will be corrected.
            </Typography>
            <Typography variant="body1" paragraph>
              13.3. The user is responsible for implementing sufficient
              procedures and checkpoints to satisfy his particular requirements
              for accuracy of data, input and output and for maintaining means,
              external to the Web Site for the reconstruction of any lost data.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              14. NO LEGAL ADVICE / PROCUREMENT REQUEST
            </Typography>
            <Typography variant="body1" paragraph>
              14.2. Parasors in the Court database provides its own citation and
              in addition also provides the equivalent citations applicable to
              relevant case law published by other publishers. By doing so
              Parasors is facilitating the user to access the database through
              various search features. Coverage of other/ all publisher
              citations should be incidental and not mandatory and user thus
              cannot claim the availability of other publisher citations as a
              matter of right.
            </Typography>
            <Typography variant="body1" paragraph>
              14.3 Parasors is not obliged to attend to procurement requests of
              users. Without prejudice to its rights, however, Parasors shall on
              its discretion procure the requested document which if procured
              shall be made available to user as part of its database.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              15. ADVERTISEMENTS
            </Typography>
            <Typography variant="body1" paragraph>
              15.1. Parasors may contain advertisements and sponsorships.
              Advertisers and sponsors are responsible for ensuring that
              material submitted for inclusion on the Web Site is accurate and
              complies with applicable laws.
            </Typography>
            <Typography variant="body1" paragraph>
              15.2. Parasors will not be responsible for the illegality of, or
              any error or inaccuracy in advertisers or sponsors materials.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              16. PRIVACY
            </Typography>
            <Typography variant="body1" paragraph>
              16.1. The user of the Web Site Parasors is subject to Parasors's
              Privacy Policy available through this link.
              #######################
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              17. NOTICE
            </Typography>
            <Typography variant="body1" paragraph>
              17.1. Except as provided in clause 8.2 hereof, no notice consent
              or the like (in this clause referred to generally as "notice")
              required or permitted to be given under this arrangement shall be
              binding unless in writing and may be given personally or sent to
              the party to be notified by pre-paid registered post, courier or
              by electronic mail or facsimile transmission at his/ its address
              as set out above or as otherwise notified in accordance with this
              clause.
            </Typography>
            <Typography variant="body1" paragraph>
              17.2. A notice given personally shall be deemed given at the time
              of delivery.
            </Typography>
            <Typography variant="body1" paragraph>
              17.3. A notice sent by post or courier in accordance with this
              clause shall be deemed given at the commencement of business of
              the recipient on the date of receipt of the notice by the
              recipient or fourth business day following its posting whichever
              is earlier.
            </Typography>
            <Typography variant="body1" paragraph>
              17.4. Notice sent by telex, electronic mail or facsimile
              transmission in accordance with this clause shall be deemed given
              at the time of its actual transmission.
            </Typography>
            <Typography variant="body1" paragraph>
              17.5. The user is to keep himself aware of the terms and
              conditions of the website, by checking this page at least once on
              a month. No further notice shall be given to the user of the
              change in the terms and conditions one is subjected to.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              18. SEVERABILITY OF PROVISIONS
            </Typography>
            <Typography variant="body1" paragraph>
              18.1. The Terms and Conditions of Use incorporate by reference,
              any notices contained on the Web Site, the Privacy Policy,
              Copyright, Terms and Conditions, which together constitute the
              entire agreement with respect to access and use of the Web Site.
              If any provision of the terms of use is unlawful, void or
              unenforceable, then that provision shall be deemed severable from
              the remaining provisions and shall not affect the validity and
              enforceability of the remaining provisions.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              19. INDEMNIFICATION
            </Typography>
            <Typography variant="body1" paragraph>
              19.1. You agree to indemnify and hold harmless provider from any
              third party claim, action, demand, loss, or damages (including
              attorney's fees and costs) arising out of or relating to your
              violation of these Terms of Use, your use of the Web Site, or your
              violation of any rights of a third party.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              20. REMEDIES FOR VIOLATIONS
            </Typography>
            <Typography variant="body1" paragraph>
              20.1. Parasors reserves the right to seek all remedies available
              at law and equity for violations of the Terms and Conditions of
              Use, including but not limited to, the right to block access from
              a particular Internet address to Parasors's website and its
              features.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              21. GOVERNING LAW AND JURISDICTION
            </Typography>
            <Typography variant="body1" paragraph>
              21.1. The Terms and Conditions of Use are governed by and
              construed in accordance with the Indian Law and any action arising
              out of, or relating to these terms shall be subject to the
              exclusive jurisdiction of the appropriate Courts at Ahmedabad,
              Gujarat only and you hereby consent and submit to the personal
              jurisdiction of such courts for the purpose of litigating any such
              action.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              22. WAIVER
            </Typography>
            <Typography variant="body1" paragraph>
              22.1. The failure by either party to enforce at any time or for
              any period any one or more of the terms and conditions herein
              shall not be a waiver of them or of the right at any time
              subsequently to enforce all terms and conditions herein.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              23. EXCLUSION OF LIABILITY
            </Typography>
            <Typography variant="body1" paragraph>
              23.1. Parasors makes reasonable effort to ensure that the
              information provided on the Web Site is accurate but does not
              guarantee or warrants its accuracy, adequacy, correctness,
              validity, completeness or suitability for any purpose.
            </Typography>
            <Typography variant="body1" paragraph>
              23.2. The information/material provided on the Web Site is
              provided on an "As Is" basis. Parasors accepts no responsibility
              with respect to the information on the Site expressly disclaims to
              the maximum limit permissible by law, all warranties, express or
              implied, including but not limiting to implied warranties of
              merchantability, fitness for a particular purpose and non-
              infringement.
            </Typography>
            <Typography variant="body1" paragraph>
              23.3. Parasors shall not be liable in contract, tort, delict or
              otherwise for any loss of whatsoever kind howsoever arising
              suffered in connection with the Service (whether or not caused by
              the negligence of Parasors).
            </Typography>
            <Typography variant="body1" paragraph>
              23.4. Parasors shall not be liable in contract, tort, delict or
              otherwise for any loss of revenue business, anticipated savings or
              profits, loss of goodwill or data or for any indirect or
              consequential loss whatsoever, howsoever arising suffered in
              connection with the Service (whether or not caused by the
              negligence of Parasors).
            </Typography>
            <Typography variant="body1" paragraph>
              23.5. Without prejudice to the generality of clause 24.3.And 24.4,
              in no event shall Parasors, its Affiliates and/or Contributors be
              liable to Subscriber for any claim(s) relating in any way to
            </Typography>
            <Typography variant="body1" paragraph>
              (i) Subscriber's inability or failure to perform legal or other
              research related work or to perform such legal or other research
              or related work properly or completely, even if assisted by
              Parasors, its Affiliates and/or Contributors or any decision made
              or action taken by Subscriber in reliance on the Data.
            </Typography>
            <Typography variant="body1" paragraph>
              (ii) any lost profits (whether direct or indirect) or any
              consequential, exemplary incidental, indirect or special damages
              relating in whole or in part to Subscribers' rights under this
              Agreement or use of or inability to use the Service, Features or
              Data even if Suppliers, its Affiliates and/or Contributors have
              been advised of the possibility of such damages.
            </Typography>
            <Typography variant="body1" paragraph>
              23.6. Subscriber shall accept sole responsibility for and Parasors
              shall not be liable for the use of the Service by Subscriber, or
              any User and Subscriber shall hold Supplier harmless and fully
              indemnified against any claims, costs, damages, loss and
              liabilities arising out of any such use
            </Typography>
            <Typography variant="body1" paragraph>
              23.7. Parasors disclaims all responsibility for any loss, injury,
              liability or damage of any kind resulting from and arising out of,
              or any way related to:
            </Typography>
            <Typography variant="body1" paragraph>
              23.7.1. Any errors in or omissions from the Web Site and its
              content, including but not limited to technical inaccuracies and
              typographical errors
            </Typography>
            <Typography variant="body1" paragraph>
              23.7.2. Any third party Web Sites or Content therein directly or
              indirectly accessed through links in the Site, including but not
              limited to any errors in or omissions therefrom
            </Typography>
            <Typography variant="body1" paragraph>
              23.7.3. The unavailability of this Site or any portion thereof;
            </Typography>
            <Typography variant="body1" paragraph>
              23.7.4. Your use of any equipment or software in connection with
              the Site; or
            </Typography>
            <Typography variant="body1" paragraph>
              23.7.5. Your use of the Site.
            </Typography>
            <Typography variant="body1" paragraph>
              23.8. Unada LexiLegal AI India Pvt. Ltd. shall not responsible if
              any information/page is downloaded from Parasors and after
              downloading complete/partial, text/information is
              altered/removed/obscured contained therein.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              24. LIMITATION OF LIABILITY
            </Typography>
            <Typography paragraph>
              24.1. Subscriber's exclusive remedy and Parasors's entire
              liability under this agreement if any, for any claim(s), damages
              relating to the copyright in data made against them individually
              or jointly whether based in contract or negligence shall be
              limited to the aggregate amount of the charges paid by subscribers
              relative to the database which is the basis of the claim(s) during
              the period of subscription. The present indemnity shall remain in
              effect for the period of continued subscription.
            </Typography>
            <Typography paragraph>
              24.2. None of the terms of this Agreement shall operate to:
            </Typography>
            <Typography paragraph>
              (i) exclude or restrict liability for fraud or for death or
              personal injury resulting from the negligence of Supplier or its
              Affiliates or the appointed agents or employees of Supplier or
              their Affiliates whilst acting in the course of their employment;
              or affect statutory rights where this Agreement is entered into as
              a consumer transaction.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              25. LINKING TO THE WEBSITE
            </Typography>
            <Typography paragraph>
              25.1. A User may provide links only to the homepage of this Web
              Site, provided
            </Typography>
            <Typography paragraph>
              25.1.1. The user do not remove or obscure, by framing or
              otherwise, advertisements, the copyright notice, or other notices
              on this Site,
            </Typography>
            <Typography paragraph>
              25.1.2. The user gives us a notice of such link by sending an
              e-mail to supports@parasors.in
            </Typography>
            <Typography paragraph>
              25.1.3. The user discontinues providing links to this Site if
              requested by us. If you wish to provide links to a section within
              the Web Site, you should forward your request to us at
              supports@parasors.in and we will notify you if permission is
              granted, and if so, the Terms and Conditions of permission.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              26. LINK TO OTHER WEBSITES AND LINKING DISCLAIMER
            </Typography>
            <Typography paragraph>
              26.1. Third party Content may appear on the Web Site or may be
              accessible via links from the Web Site. Parasors shall not be
              responsible for and assumes no liability for any mistakes,
              misstatements of law, defamation, slander, libel, omissions,
              falsehood, obscenity, pornography or profanity in the statements,
              opinions, representations or any other form of Content contained
              in any third party content appearing on the Web Site. Parasors has
              no control or authority either over the Content or presentations
              thereof, which solely represent the thoughts of the author and is
              neither endorsed by, nor does it reflect the belief of Parasors.
            </Typography>
            <Typography paragraph>
              26.2. Any claim either in contract, tort or otherwise relating to
              damages, loss, injury or determined caused by, or on account of
              reliance on such Content, is wholly disclaimed by Parasors.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              27. Force Majeure
            </Typography>
            <Typography paragraph>
              27.1. Parasors's performance under this Agreement is subject to
              interruption and delay due to causes beyond its reasonable control
              such as acts of God, acts of any Government, war or other
              hostility, civil disorder, the elements, fire, explosion, power
              failure, failure of the Internet and other networks beyond the
              control of Parasors, equipment failure, industrial or labor
              dispute, inability to obtain essential supplies and the like.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              28. Dispute Resolution
            </Typography>
            <Typography variant="body1" paragraph>
              28.1 Any dispute or difference between Provider and Subscriber
              arising out of or relating to the existence, validity,
              interpretation, performance or termination of, or otherwise in
              connection with this Agreement ("Dispute"), shall at first
              instance be attempted to be amicably settled between the parties
              through good faith negotiations. Either party shall be entitled to
              invoke such negotiations by giving to the other party a notice to
              that effect ("Dispute Notice"). If a Dispute is not resolved by
              way of good faith negotiations within a period of Sixty (60) days
              from the date when the Dispute Notice was received by the
              receiving party then either party shall be entitled to refer the
              Dispute to arbitration in the manner described below.
            </Typography>
            <Typography variant="body1" paragraph>
              28.2 Any Dispute not resolved by way of good faith negotiations
              within a period of sixty (60) days from the date when the Dispute
              Notice was received by the receiving party, shall be finally
              resolved by arbitration in accordance with the provisions of this
              clause 28.
            </Typography>
            <Typography variant="body1" paragraph>
              28.3 The provisions of the Arbitration and Conciliation Act, 1996,
              as amended ("Arbitration Act"), will apply to such arbitration:
            </Typography>
            <Typography variant="body1" paragraph>
              28.4 The arbitration shall be conducted by a single arbitrator who
              shall be appointed by the mutual consent of both parties. If the
              parties are not able to reach a decision to appoint the arbitrator
              within sixty (60) days of the date when the good faith
              negotiations mentioned above have failed, then the Dispute shall
              be referred to a panel of three (3) arbitrators. One (1)
              arbitrator shall be appointed by each party within thirty (60)
              days of the parties failing to reach an agreement to appoint the
              sole arbitrator as aforesaid. The two (2) arbitrators so appointed
              by the parties shall appoint the third presiding arbitrator within
              thirty (30) days of their appointment, failing which the third
              presiding arbitrator shall be appointed in accordance with the
              provisions of the Arbitration Act.
            </Typography>
            <Typography variant="body1" paragraph>
              28.5 The arbitration proceedings shall be conducted in English.
            </Typography>
            <Typography variant="body1" paragraph>
              28.6 The place of arbitration shall be Ahmedabad, Gujarat, India.
            </Typography>
            <Typography variant="body1" paragraph>
              28.7 The award of the arbitrator(s) shall be final and binding on
              both parties. The arbitrator(s) shall state reasons for its/their
              findings in writing.
            </Typography>
            <Typography variant="body1" paragraph>
              28.8 The costs of arbitration shall be determined by the
              arbitrator(s).
            </Typography>
            <Typography variant="body1" paragraph>
              28.9 In relation to any arbitration proceedings, the parties agree
              that the courts at Ahmedabad, Gujarat, shall have exclusive
              jurisdiction to the extent the court has jurisdiction under the
              Arbitration Act.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              29. PAYMENTS
            </Typography>
            <Typography paragraph>
              29.1. Payments can be made either by a cheque/DD/ NEFT/RTGS/ UPI /
              Credit Card/ Debit Card / Net Banking. No cash will be accepted.
              The payment made against the Subscription is not refundable under
              any circumstances.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              30.RENEWALS / DELAYEDPAYMENTS
            </Typography>
            <Typography paragraph>
              30.1 Subscriber intending to renew the subscription must ensure
              that renewal request along with the payment as due at the time of
              and for renewal is sent to Parasors on or before the last due date
              i.e. the date until when the last subscription period is valid.
              Subscriber who has renewed/made payment before the expiry of the
              renewal period is deemed to be renewed and will be bound by Terms
              and Conditions of this Agreement. The subscribers account will be
              deactivated on the expiry of validity period of subscription.{' '}
            </Typography>
            <Typography paragraph>
              30.2 Without prejudice to its rights, Parasors in its sole
              discretion shall reserve the right to extend the subscription/
              access period in the event of receiving assurance to clear the
              renewal charges within a period not later than 15 days from the
              date of expiry of valid subscription period. Parasors also
              reserves the right to recover the assured amount, in the event of
              non-renewal and non-payment from the subscriber in any legal
              manner, under civil or criminal law, and the subscriber shall be
              liable for any costs thereof
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              31. MODIFICATIONS
            </Typography>
            <Typography paragraph>
              31.1. The prices, terms and conditions and/ or other matters
              provided in the Price List, and any modifications carried out by
              Parasors and any notices served upon the subscriber by Parasors
              shall be deemed to be included in the terms and conditions herein.
            </Typography>
            <Typography paragraph>
              31.2. Parasors reserves the right to modify the terms and
              conditions including the subscription and the charges, and to
              introduce new ones. Such modifications shall become effective
              forthwith on the issue of either specifically to the subscriber or
              generally a notice to that effect by Parasors by electronic mail.
            </Typography>
            <Typography paragraph>
              31.3. The subscription amount paid by the subscriber shall be for
              the fixed term/ plan and any modification in the subscription
              charges shall be applicable on the commencement of next renewed
              plan term. However, the introduction of any additional feature/
              database by third party content provider through Parasors will be
              made available to the existing subscriber only after making up the
              deficit payment.
            </Typography>
            <Typography paragraph>
              31.4. The subscriber’s continued use of service after the
              publication of the notice conveying the modification shall
              conclusively be deemed to be the acceptance of the modified terms
              and conditions.
            </Typography>
            <Typography paragraph>
              31.5. Parasors has the right to prevent access to all or part of
              the Web Site without notice, if the conduct of the user is in
              contravention with the Terms and Conditions of Use or the
              applicable laws. Parasors shall have a right to make such
              additions to, deletions from and other modifications, as it may
              deem fit, of the database and in the database, and / or the manner
              of presenting and providing such databases, including the basic
              structure and features thereof, without giving notice thereof to
              the subscriber.
            </Typography>
            <Typography paragraph>
              31.6.Parasors shall not be liable or responsible, whether under
              law or equity, for any delays, defaults or interruptions in the
              performance of the Service.
            </Typography>
            <Typography variant="body1" paragraph>
              Name of Subscriber: .....................................
            </Typography>
            <Typography variant="body1" paragraph>
              Signature
            </Typography>
            <Typography variant="body1" paragraph>
              For: Unada LexiLegal AI India Pvt. Ltd.
            </Typography>
            <Typography variant="body1" paragraph>
              Signature
            </Typography>
            <Typography variant="body1" paragraph>
              Unada LexiLegal AI India Pvt. Ltd.
            </Typography>
            <Typography variant="body1">
              Regd. Off: 550, Iscon Emporio, Jodhpur Char Rasta, Ahmedabad -
              380015
            </Typography>
            <Typography variant="body1">Phone: +917862024802</Typography>
            <Typography variant="body1">Web: www.parasors.in</Typography>
          </Fade>
        </Box>
      </Paper>
      <Footer />
    </>
  );
};

export default Termsandcondition;
