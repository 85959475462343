import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { GetTokenFunc } from '../../utils/GetToken';
export const useFileUpload = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/file/multiple-upload', data, {
      //   credential: credentials,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
  });
  //   return { data, isLoading, error, mutate };
};

export const useUpdateFileUpload = () => {
  return useMutation(async (data) => {
    return await axios.put('api/v1/file/update-file-upload', data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
    // Assuming your API returns some data
  });
};

export const useSingleFileUpload = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/file/single-upload', data, {
      //   credential: credentials,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
  });
  //   return { data, isLoading, error, mutate };
};
