export const GetTokenFunc = () => {
  var cookies = document.cookie.split('; ');

  // Loop through each cookie to find the BearerToken
  var bearerToken;
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    if (cookie.startsWith('Bearer=')) {
      // Extract the BearerToken value
      bearerToken = cookie.substring('Bearer='.length);
      break;
    }
  }
  return bearerToken;
};
