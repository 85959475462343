import React from 'react';
import { Box, Typography, List, ListItem, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetArticals } from '../../Hooks/blogHooks.js';
const ViewNews = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: articals } = useGetArticals();
  return (
    <Paper elevation={0} sx={{ height: '100%', width: '90%', mx: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // width: '100%',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            sx={{ color: 'primary.main', cursor: 'pointer', mt: 2 }}
          />
          <Box
            component={'img'}
            src={parasorc}
            alt=""
            sx={{ height: 'auto', maxWidth: '150px', mt: 2 }}
          />
        </Box>
        <Box sx={{ p: 4 }}>
          {/* Title */}
          <Typography variant="h4" component="h1" gutterBottom>
            {location?.state?.title}
          </Typography>

          {/* Overview Section */}
          <Typography variant="h5" component="h2" gutterBottom>
            Overview
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
            {location?.state?.overView?.replace(/<\/?[^>]+(>|$)/g, '')}
          </Typography>

          {/* Analysis Section */}
          <Typography variant="h5" component="h2" gutterBottom>
            Analysis
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
            {location?.state?.analysis?.replace(/<\/?[^>]+(>|$)/g, '')}
          </Typography>

          {/* Key Insights Section */}
          <Typography variant="h5" component="h2" gutterBottom>
            Key Insights
          </Typography>
          {location?.state?.keyInsights?.map((item, index) => (
            <List>
              <ListItem>
                <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                  {item?.title?.replace(/<\/?[^>]+(>|$)/g, '')}
                </Typography>
              </ListItem>
            </List>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ViewNews;
