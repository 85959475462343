import { Box, Button, Typography, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import BGImage from '../../Assets/mainPage/chatMessageBg.svg';
import * as Styles from '../../Common/Styles.js';
import {
  useCreateChatMessages,
  useGetUsersChatMessages,
  useShareChatMessages,
} from '../../Hooks/chatHooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Markdown from 'react-markdown';
import {
  useGetUsersSinglePdfs,
  useGetUsersPdfs,
  useDeleteChat,
} from '../../Hooks/FileUpload/fileHooks.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useGetBatchList } from '../../Hooks/ProfileHooks';
const ViewShareChat = ({ chatData, setPageNumber, setShowPdf }) => {
  // Pdf Show time
  const [gridSize, setGridSize] = useState(false);
  const [helpModel, setHelpModal] = useState(false);
  const [isChatIconHovered, setChatIconHovered] = useState(false);
  const [isShareIconHovered, setShareIconHovered] = useState(false);
  const LocationData = useLocation();
  const handleChatIconHover = () => {
    setChatIconHovered(true);
    setShareIconHovered(false);
  };
  const handleShareIconHover = () => {
    setChatIconHovered(false);
    setShareIconHovered(true);
  };
  const handleMouseLeaveHoevr = () => {
    setChatIconHovered(false);
    setShareIconHovered(false);
  };

  const navigate = useNavigate();
  // const id = chatData?._id;

  const { id } = useParams();

  //   Message box
  const [chatId, setChatId] = useState(null);
  const [massages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { mutate: createChatMessage, isLoading: chatMessageLoading } =
    useCreateChatMessages(id);
  const queryClient = useQueryClient();
  const { data: ChatMessages, refetch: ChatMessagesRefecth } =
    useGetUsersChatMessages(id);
  const { mutate: shareChatMessage, isLoading: shareChatMessageLoading } =
    useShareChatMessages(id);

  useEffect(() => {
    ChatMessagesRefecth(id);
  }, [chatId]);

  const messageBoxRef = useRef();

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [ChatMessages]);

  const copyMessagesToClipboard = () => {
    const messages = ChatMessages?.data?.data
      ?.map((item) => `${item.role}: ${item.message}`)
      .join('\n\n');
    navigator.clipboard.writeText(messages).then(
      () => {
        toast.success('Messages copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
        toast.error('Failed to copy messages');
      }
    );
  };
  return (
    <Box
      sx={{
        flex: gridSize ? '1 1 83%' : ' 1 1 60%',
        transition: 'flex-basis 0.5s linear',
        mt: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          mt: 0,
        }}
      >
        <Box
          sx={{
            ...Styles.MessageViewStyle,
            width: '100%',
            mx: 'auto',
            mt: 2,
            boxShadow: '0px 10px 20px 0px rgba(147, 147, 147, 0.25)',
            borderRadius: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '20px',
              py: '0px',
              borderBottom: '2px solid',
              // borderBottomColor: 'primary.main',
              borderBottomColor: '#68858C',
              width: '90%',
              mx: 'auto',
              p: { xl: 2, lg: 1, md: 1 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '95%',
                mx: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  maxWidth: '300px',
                  overflowX: 'auto',
                  // textOverflow: 'ellipsis',
                }}
              >
                <ArrowBackIcon
                  onClick={() => navigate(-1)}
                  sx={{ color: 'primary.main', cursor: 'pointer' }}
                />
              </Box>
              <Button
                onClick={copyMessagesToClipboard}
                startIcon={<ContentCopyIcon />}
                sx={{
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'rgba(104, 133, 140, 0.1)',
                  },
                }}
              >
                Copy
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '45%',
                  alignItems: 'center',
                }}
              ></Box>
            </Box>
          </Box>
          <Box ref={messageBoxRef} sx={mainChatBox}>
            {ChatMessages?.data?.data?.map((item, index) => {
              let pageNumber;
              let message = '';
              message = item?.message;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems:
                      item.role === 'user' ? 'flex-end' : 'flex-start',
                    mb: '20px',
                    mt: '20px',
                    width: '90%',
                    mx: 'auto',
                  }}
                >
                  <div
                    style={{
                      textAlign: item.role === 'user' ? 'right' : 'left',
                      marginTop: '25px',
                      position: 'relative',
                    }}
                  >
                    {item.role === 'user' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          // width: '100%',
                          right: '0px',
                          float: 'right',
                          marginTop: '-20px',
                          // marginLeft: '40px',
                          ml: 'auto',
                          // mr: 5,
                        }}
                      >
                        <Typography sx={Styles.timeStaps}>
                          {new Date(item.createdAt).getHours()}:{' '}
                          {new Date(item.createdAt).getMinutes()}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          right: '0px',
                          marginTop: '-20px',
                          // marginLeft: '40px',
                          // ml: 4,
                          mr: 0,
                        }}
                      >
                        <Typography sx={Styles.timeStaps}>
                          {new Date(item.createdAt).getHours()}:{' '}
                          {new Date(item.createdAt).getMinutes()}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        ...Styles.messagesText,
                        backgroundColor:
                          item.role === 'user' ? 'secondary.main' : '#CEE8EE',
                        color: item.role === 'user' ? '#fff' : '#000',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box>
                          <Markdown>{message}</Markdown>
                        </Box>
                        <Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
                          {pageNumber ? (
                            <Button
                              sx={Styles.pdfCountPageNumber}
                              onClick={() => {
                                setPageNumber(10);
                              }}
                            >
                              {pageNumber}
                            </Button>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ViewShareChat;

const mainChatBox = {
  height: '100%',
  minHeight: { xl: '70vh', lg: '70vh', md: '70vh', sm: '90vh', xs: '90vh' },
  maxHeight: '70vh',
  //   minHeight: '90vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  margin: '0px',
  marginTop: { xl: '20px', lg: '5px', md: '0px' },
  backgroundImage: `url(${BGImage})`,
  backgroundSize: 'cover',
};
