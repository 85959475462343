import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import Header from '../HeaderUi/Header';
import Footer from '../HomeChat/Footer.jsx';
import * as Styles from '../../Common/Styles.js'; // Assuming you have common styles for consistency
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const AboutParasors = () => {
  return (
    <>
      <Paper elevation={0}>
        {/* Header Section */}
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }} align="center">
                About Us
              </Typography>
            </Fade>
          </Box>
        </Box>

        {/* Content Section */}
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            mt: 2,
            textAlign: 'justify',
          }}
        >
          <Fade bottom duration={2000}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{ ...Styles.headHeading, textAlign: 'left' }}
            >
              ‘Meet your in-house virtual Paralegal – Para’
            </Typography>
            <Typography paragraph>
              Parasors.in stands at the forefront of a new era in legal
              technology, committed to redefining the landscape of legal
              research, document analysis, and drafting in India. Launched on
              July 25, 2024, by Unada LexiLegal AI India Private Limited, we are
              dedicated to providing cutting- edge, AI-powered solutions that
              elevate the practice of law. Our platform is meticulously designed
              to cater to the unique demands of the Indian legal system,
              offering unparalleled resources and tools to enhance the
              efficiency and precision of legal professionals.
            </Typography>
            <Typography paragraph>
              With comprehensive coverage of Supreme Court and High Court
              judgments dating back to 1950, as well as an extensive database of
              judgements from other courts of authorities and Central and State
              Acts, Parasors.in ensures that legal practitioners have efficient
              access to a wealth of information. Beyond traditional legal
              research, we integrate the latest news, prominent articles, and
              expert opinions, providing a holistic view of the legal landscape
              and keeping users informed and updated of significant
              developments.
            </Typography>
            <Typography paragraph>
              Our mission is to empower legal practitioners, law firms, and
              businesses by providing innovative AI-driven tools that simplify
              legal processes and improve productivity. By automating routine
              tasks, we enable professionals to devote more time to strategic
              matters, thereby enhancing the quality of legal services delivered
              to clients. We envision a future where AI technology is seamlessly
              integrated into the fabric of legal practice, transforming the way
              legal research, drafting, and document management are conducted.
              We want AI to assist and empower the lawyers. Parasors.in aspires
              to be the leading AI-powered legal assistant in India,
              continuously evolving to meet the dynamic needs of the legal
              industry and setting new standards for efficiency and accuracy.
            </Typography>

            {/* Values Section */}
            <Zoom>
              <Typography
                variant="h6"
                component="h2"
                sx={{ ...Styles.headHeading, textAlign: 'left' }}
              >
                Our Values
              </Typography>
            </Zoom>
            <Typography paragraph>
              {' '}
              Our core values reflect our commitment to:{' '}
            </Typography>
            <Typography paragraph>
              - <b>Innovation</b>: We are dedicated to advancing legal
              technology, constantly refining our solutions to address the
              evolving challenges faced by legal professionals.
            </Typography>
            <Typography paragraph>
              - <b>Accuracy</b>: We prioritize precision in our tools and
              services, ensuring that our users can trust the quality of the
              information and insights they receive.
            </Typography>
            <Typography paragraph>
              - <b>Empowerment</b>: We aim to empower legal practitioners by
              simplifying complex tasks, allowing them to focus on delivering
              superior legal outcomes.
            </Typography>

            {/* Solutions */}
            <Fade bottom duration={2000}>
              <Typography
                variant="h6"
                component="h2"
                sx={{ ...Styles.headHeading, textAlign: 'left' }}
              >
                Our Solutions
              </Typography>
              <Typography paragraph>
                Parasors.in offers a suite of AI-driven tools, each tailored to
                meet the varied needs of legal professionals:
              </Typography>
              <Typography paragraph>
                - <b>ParaSearch</b>: enables in-depth case law research,
                leveraging advanced AI technology to quickly identify relevant
                judgments and legal precedents. research.
              </Typography>
              <Typography paragraph>
                - <b>ParaSors</b>: facilitates seamless citation management,
                helping legal professionals maintain accuracy and organization
                in their references.
              </Typography>
              <Typography paragraph>
                - <b>ParaDoc (Coming Soon)</b>: will bring a new level of
                sophistication to document analysis, offering insights that
              </Typography>
              <Typography paragraph>
                - <b>ParaDraft (Coming Soon)</b>:will streamline the drafting
                process, incorporating AI to enhance the quality and accuracy of
                legal documents.
              </Typography>
            </Fade>

            {/* Leadership */}
            <Zoom>
              <Typography
                variant="h6"
                component="h2"
                sx={{ ...Styles.headHeading, textAlign: 'left' }}
              >
                Leadership
              </Typography>
            </Zoom>
            <Typography paragraph>
              <b>Rohan Hundia</b> – CEO: Leading Parasors.in with a strategic
              vision for revolutionizing legal technology.
            </Typography>
            <Typography paragraph>
              <b>Aditya Vijay</b> – CTO: Driving technological advancements and
              ensuring AI capabilities are state-of-the-art.
            </Typography>

            {/*  */}
            <Typography paragraph>
              Parasors.in distinguishes itself as more than just a legal
              research tool; it is a comprehensive platform that brings together
              technology, legal expertise, and an intuitive user experience, all
              tailored for the Indian legal system. Our dedication to innovation
              and excellence ensures that legal professionals can navigate the
              complexities of law with confidence and agility.
            </Typography>
            <Typography paragraph>
              Experience the future of legal research and document management
              with Parasors.in—where precision meets progress.
            </Typography>
          </Fade>
        </Box>

        {/* Footer */}
        <Footer />
      </Paper>
    </>
  );
};

export default AboutParasors;
