import React, { useRef } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  Tooltip,
  Skeleton,
} from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import forwardicon from '../../Assets/mainPage/forwardicon.svg';
import backwardicon from '../../Assets/mainPage/backwardicon.svg';
import blogsone from '../../Assets/mainPage/blogsone.svg';
import slidetwo from '../../Assets/mainPage/slidetwo.svg';
import menlogo from '../../Assets/mainPage/menlogo.svg';
import greyforwardicon from '../../Assets/mainPage/greyforwardicon.svg';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import saveposticon from '../../Assets/mainPage/saveposticon.svg';
import { useGetNews } from '../../Hooks/blogHooks';
import Fade from 'react-reveal/Fade';
import { newsArticles } from './data.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const News = () => {
  const scrollBoxRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const {
    data: newsData,
    isLoading: newsLoading,
    error: newsError,
    refetch: newsDataRefetch,
  } = useGetNews();

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          background: '#fff',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 0,
          p: 2,
        }}
      >
        <Box
          sx={{
            width: '80%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Fade top duration={1000}>
            <Typography sx={Styles.boxMainText}> Latest News </Typography>
          </Fade>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Box
              component={'img'}
              src={backwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                borderRadius: '10px',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  // transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollBackward}
            />
            <Box
              component={'img'}
              src={forwardicon}
              sx={{
                height: 'auto',
                width: 'auto',
                cursor: 'pointer',
                borderRadius: '10px',
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  // transform: 'scale(1.02)',
                  boxShadow: 6,
                },
              }}
              onClick={scrollForward}
            />
          </Box>
        </Box>
        <Box sx={scrollingImg} ref={scrollBoxRef}>
          {newsData?.data?.map((item, index) => (
            <Fade top duration={500}>
              {newsLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={290}
                  height={290}
                  sx={{ mt: 1 }}
                />
              ) : (
                <Box
                  key={index}
                  sx={{
                    mt: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    width: '90%',
                    mx: 'auto',
                    ml: 1,
                    mr: 1,
                    // cursor: 'pointer',
                  }}
                >
                  <Box sx={blogsMainBox}>
                    <Box sx={Styles.insideBoxHeader}>
                      <Box
                        component={'img'}
                        src={parasorc}
                        alt=""
                        sx={{
                          position: 'absolute',
                          top: '20px', // Adjust this value as needed to position the image vertically
                          left: '50%',
                          transform: 'translateX(-50%)',
                          height: 'auto',
                          maxWidth: '150px',
                        }}
                      />
                      <Typography
                        sx={{
                          ...Styles.boxSubText,
                          fontSize: '14px',
                          mt: 8,
                          fontWeight: 800,
                          textSize: 'bold',
                          textAlign: 'center',
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        ...Styles.subheadingtabletext,
                        textAlign: 'left',
                        width: '100%',
                        whiteSpace: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        mt: 0.2,
                      }}
                    >
                      {item?.content}
                      {/* {item?.contentSnippet} */}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Fade>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default News;

const scrollingImg = {
  mt: 2,
  height: 'auto',
  width: '100%',
  mx: 'auto',
  maxWidth: '80%',
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '20px',
  whiteSpace: 'nowrap',
  pb: 2,
};

const blogsMainBox = {
  borderRadius: '10px',
  p: 1,
  height: '100%',
  minHeight: '262px',
  width: '260px',
  mt: 0.5,
  background: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 1px 2px 1px rgba(147, 147, 147, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
};

const savePost = {
  background: 'rgba(247, 249, 251, 1)',
  p: 0.8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '95%',
  mx: 'auto',
  mt: 2,
  borderRadius: '10px',
};
