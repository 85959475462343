import React, { useRef } from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import forwardicon from '../../Assets/mainPage/forwardicon.svg';
import backwardicon from '../../Assets/mainPage/backwardicon.svg';
import slideone from '../../Assets/mainPage/slideone.svg';
import slidetwo from '../../Assets/mainPage/slidetwo.svg';
import Footer from '../HomeChat/Footer.jsx';
import NewsComponent from '../HomeChat/OpinionComponent.jsx';
import outercircle from '../../Assets/OuterImg/outercircle.svg';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Articles from '../HomeChat/Articles.jsx';
const ContentCoverage = () => {
  const scrollBoxRef = useRef(null);

  const boxText = [
    // {
    //   heading: 'District Courts',
    //   subheadings:
    //     'Ask our chat bot questions of Judgments from 1950-2010 of District courts.',
    // },
    {
      heading: 'High Court',
      subheadings:
        'Ask our chat bot questions of Judgments from 1950-2010 of High court.',
    },
    // {
    //   heading: 'Subordinate Court',
    //   subheadings:
    //     'Ask our chat bot questions of Judgments from 1950-2010 of Subordinate Court.',
    // },
    {
      heading: 'Supreme Court',
      subheadings:
        'Ask our chat bot questions of Judgments from 1950-2010 of Supreme Court.',
    },
  ];

  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '30vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }}>
                Content We Are Offering
              </Typography>
            </Fade>
            <Zoom duration={1000}>
              <Typography sx={{ ...Styles.subheading, fontSize: '12px' }}>
                Our Legal DataBank
              </Typography>
            </Zoom>
          </Box>
        </Box>
        <Box sx={mainBoxesStyles}>
          {boxText.map((item, index) => (
            <Box key={index} sx={insideBoxStyle}>
              <Box sx={iconStyle}>
                {/* <ShieldIcon /> */}
                <Box
                  component={'img'}
                  src={outercircle}
                  alt=""
                  sx={{ height: 'auto', width: '80px' }}
                />
              </Box>
              <Fade top duration={2000}>
                <Typography
                  sx={{
                    ...Styles.boxMainText,
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#1F2937',
                  }}
                >
                  {item.heading}
                </Typography>
              </Fade>
              <Typography
                sx={{
                  ...Styles.boxSubText,
                  fontSize: '14px',
                  color: '#6B7280',
                }}
              >
                {item.subheadings}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* <NewsComponent /> */}
        <br />
        <br />
        <Articles />
      </Paper>
      <Footer />
    </div>
  );
};

export default ContentCoverage;

const insideBoxStyle = {
  background:
    'linear-gradient(135deg, rgba(100, 100, 255, 0.1) 0%, rgba(255, 255, 255, 1) 100%)',
  borderRadius: '16px',
  p: 3,
  pt: 5, // Increased top padding to accommodate the icon
  width: '100%',
  maxWidth: '300px',
  position: 'relative',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
};

const iconStyle = {
  position: 'absolute',
  top: -40,
  left: 16,
};

const mainBoxesStyles = {
  width: '100%',
  mx: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  // alignItems: "stretch",
  mt: 10,
  gap: '100px',
};
