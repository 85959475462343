import React from 'react';
import ChatPdfMessage from './ChatPdfMessage';
import { Stack, Box } from '@mui/material';
import MessageBoxMobile from './MessageBoxMobile';
const MainChatFile = () => {
  return (
    <Stack>
      <Box
        sx={{
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'none',
            xs: 'none',
          },
        }}
      >
        <ChatPdfMessage />
      </Box>
      <Box
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'flex',
            xs: 'flex',
          },
        }}
      >
        <MessageBoxMobile />
      </Box>
    </Stack>
  );
};

export default MainChatFile;
