import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetTokenFunc } from '../utils/GetToken';

export const useGetUsersChatMessages = (id) => {
  return useQuery({
    queryKey: ['user-chats', id],
    queryFn: async () => {
      if (!id) return;
      return await axios.get(`api/v1/chat/${id}`, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useCreateChatMessages = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/chat', data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
  });
};

export const useShareChatMessages = () => {
  return useMutation(async (id) => {
    return await axios.post(
      'api/v1/chat/send-mail/' + id,
      {},
      {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      }
    );
  });
};

export const useCreateNewChat = () => {
  return useMutation({
    mutationKey: ['create-chat'],
    mutationFn: async (data) => {
      console.log('useCreateNewChat', useCreateNewChat);
      return await axios.post('api/v1/chat-session', data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetNewChatList = () => {
  return useQuery({
    queryKey: ['new-chat-list'],
    queryFn: async () => {
      return await axios.get('api/v1/chat-session?userFiles=true', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useDeleteNewChat = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }) => {
      return await axios.delete('api/v1/chat-session/' + id, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['chta-delete'] });
    },
  });
};

export const useGetFollowUpMessages = (id) => {
  return useQuery({
    queryKey: ['follow-up', id],
    queryFn: async () => {
      // if (!id) return;
      return await axios.get(`api/v1/chat-session/follow-up/${id}`, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetNewChatNameGenerator = (id, EnableGenerateChatName) => {
  return useQuery({
    enabled: false,
    queryKey: ['generate-chat-name', id],
    queryFn: async () => {
      // if (!id) return;
      return await axios.get(`api/v1/chat-session/generate-chat-name/${id}`, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
