import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Grid, Switch } from '@mui/material';
import HeaderPhone from '../HeaderUi/HeaderPhone';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import ticsign from '../../Assets/mainPage/ticsign.svg';
import Footer from '../HomeChat/Footer.jsx';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import QRCodeStaticScanner from '../Profile/QRCodeStaticScanner';
const Plans = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '33vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Header />
            <Fade top duration={1000}>
              <Typography sx={{ ...Styles.headHeading, mt: 12 }}>
                Ready to get started?
              </Typography>
            </Fade>
            <Zoom duration={1000}>
              <Typography sx={{ ...Styles.subheading, fontSize: '12px' }}>
                14 days unlimited free trial. No contract or credit card
                required.
              </Typography>
            </Zoom>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 1,
                gap: '10px',
              }}
            >
              <Typography sx={{ ...Styles.gridheaderSubText, fontWeight: 700 }}>
                Monthly
              </Typography>
              <Switch
                {...label}
                defaultChecked
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'white',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: 'rgba(96, 91, 255, 1)',
                  },
                }}
              />
              <Typography sx={{ ...Styles.gridheaderSubText, fontWeight: 700 }}>
                Yearly
              </Typography>
            </Box> */}
          </Box>
        </Box>
        <Box sx={{ minHeight:"60vh" }}>
          <QRCodeStaticScanner />
          {/* <Paper
            elevation={0}
            sx={{
              boxShadow: '0px 0px 12px rgba(230, 233, 245, 1)',
              width: {
                xl: '70%',
                lg: '70%',
                md: '70%',
                sm: '100%',
                xs: '100%',
              },
              mx: 'auto',
            }}
          >
            <Box sx={{ overflowX: 'auto' }}>
              <Grid container sx={{ minWidth: '800px' }}>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} sx={gridDesign}>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        ...Styles.gridheaderSubText,
                        fontWeight: 700,
                        textAlign: 'left',
                      }}
                    >
                      Compare plans
                    </Typography>
                    <Typography sx={{ ...Styles.subheadingtabletext, mt: 1.5 }}>
                      Choose your workspace plan according to your
                      organisational plan
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      Number of Users
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      Users Per Page
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      Account Management
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      Analytics and reporting
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      Email Support
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2.2,
                      mt: 0.2,
                    }}
                  >
                    <Typography sx={Styles.tableBodyText}>
                      24/7 customer support
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} sx={gridDesign}>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.gridheadermainText}>
                      <span style={{ fontSize: '12px' }}>Rs.</span> 0{' '}
                      <span style={{ fontSize: '12px' }}> /Free </span>
                    </Typography>
                    <Box sx={Styles.planBtn}>Choose This Plan</Box>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      20 Pages
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      5 Pages
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      mt: 2.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      mt: 2.5,
                    }}
                  ></Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} sx={gridDesign}>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.gridheadermainText}>
                      <span style={{ fontSize: '12px' }}>Rs.</span> 1000{' '}
                      <span style={{ fontSize: '12px' }}> /Premium </span>
                    </Typography>
                    <Box sx={Styles.planBtn}>Choose This Plan</Box>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 1,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      20 Pages
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.subheadingtabletext,
                        textAlign: 'center',
                      }}
                    >
                      Pages Add-ons on Demand
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      20 Pages
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      mt: 2.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3} sx={gridDesign}>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                    }}
                  >
                    <Typography sx={Styles.gridheadermainText}>
                      <span style={{ fontSize: '12px' }}>Rs.</span> 5000{' '}
                      <span style={{ fontSize: '12px' }}> /IP Based </span>
                    </Typography>
                    <Box sx={Styles.planBtn}>Choose This Plan</Box>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 1,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      Unlimited
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.subheadingtabletext,
                        textAlign: 'center',
                      }}
                    >
                      Pages Add-ons on Demand
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 1,
                    }}
                  >
                    <Typography sx={Styles.tableBodySubText}>
                      Unlimited
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.subheadingtabletext,
                        textAlign: 'center',
                      }}
                    >
                      Pages Add-ons on Demand
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(230, 233, 245, 1)',
                      p: 2,
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={ticsign}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          </Box> */}
        </Box>
      </Paper>
      <Footer />
    </>
  );
};

export default Plans;

const gridDesign = {
  borderRight: '1px solid rgba(230, 233, 245, 1)',
  // '&:hover': {
  //   backgroundColor: 'rgba(230, 233, 245, 1)',
  //   borderBottom: '1px solid rgba(230, 233, 245, 1)',
  // },
};
