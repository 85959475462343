import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  InputAdornment,
  Input,
  Button,
  styled,
} from '@mui/material';
import rightarr from '../../Assets/mainPage/rightarr.svg';
import rightarrwhite from '../../Assets/mainPage/rightarrwhite.svg';
import HeaderPhone from '../HeaderUi/HeaderPhone';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import searchimg from '../../Assets/mainPage/searchimg.svg';
import Footer from '../HomeChat/Footer.jsx';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
const FaqPage = () => {
  const [tab, setTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const tabZero = [
    {
      id: 1,
      question: 'What is Parasors? ',
      answer:
        ' Parasors is an online platform that offers comprehensive legal solutions and services, including legal research tools, case law databases, and other resources for legal professionals ',
    },
    {
      id: 2,
      question: ' How can I contact Parasors for support or inquiries?',
      answer:
        '  You can reach us through our Contact Us page, email us at business@unada.io, or call our customer service number provided on the website.',
    },
  ];
  const tabOne = [
    {
      id: 1,
      question: 'What types of legal research tools does Parasors provide? ',
      answer:
        ' Parasors provides a range of legal research tools, including a comprehensive case law database, statutory law, legal articles, and advanced search functions to help legal professionals find relevant information quickly and efficiently ',
    },
    {
      id: 2,
      question: ' How can I access the case law database?',
      answer:
        '   To access the case law database, you need to log in to your account and navigate to the "PARASORS Chat" section. Use the chat bar to ask specific questions. ',
    },
    {
      id: 3,
      question:
        'Does Parasors offer training or tutorials on how to use the platform? ',
      answer:
        '  Yes, we offer detailed tutorials, and customer support to help you make the most of our platform. Visit our ________ page for more information. ',
    },
  ];
  const tabTwo = [
    {
      id: 1,
      question: 'What payment methods are accepted on Parasors? ',
      answer:
        'We accept various payment methods, including credit/debit cards, net banking, and UPI. For detailed information, please visit our Payment Information page. ',
    },
    {
      id: 2,
      question: 'How do I get a receipt for my subscription payment? ',
      answer:
        ' After completing your payment, a receipt will be emailed to you. You can also download your payment receipts from the "________" section in your account settings. ',
    },
  ];
  const tabThree = [
    {
      id: 1,
      question: 'I forgot my password. How can I reset it? ',
      answer:
        'Click on the "Forgot Password" link on the login page, enter your registered email address, and follow the instructions to reset your password.',
    },
    {
      id: 2,
      question:
        'What should I do if I encounter technical issues on the website? ',
      answer:
        ' If you experience any technical issues, please contact our support team immediately through the Contact Us page, and we will assist you promptly.',
    },
  ];
  const tabFour = [
    {
      id: 1,
      question: 'How does Parasors ensure the security of my data?  ',
      answer: `We use advanced security measures, including encryption and secure servers, to protect your personal information and ensure your data's confidentiality and integrity. `,
    },
    {
      id: 2,
      question: 'Where can I find the privacy policy? ',
      answer:
        ' Our privacy policy is available on the Privacy Policy page. It outlines how we collect, use, and protect your personal information. ',
    },
  ];
  const tabFive = [
    {
      id: 1,
      question: 'How do I create an account on Parasors? ',
      answer: `To create an account, click on the "Sign Up" button on the homepage and follow the instructions to register with your email address and create a password. `,
    },
    {
      id: 2,
      question: 'What subscription plans are available? ',
      answer:
        ' We offer various subscription plans tailored to different needs, including Premium and IP Based plans. For detailed information, please visit our Subscription Plans page.  ',
    },
    {
      id: 3,
      question: 'Can I change or cancel my subscription plan?  ',
      answer:
        'Yes, you can change or cancel your subscription at any time through your account settings. For assistance, please contact our support team.  ',
    },
  ];
  return (
    <>
      <Paper elevation={0}>
        <Box
          sx={{
            position: 'relative',
            height: '33vh',
            background: '#F5F5F7',
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              width: '100%',
              zIndex: 1,
              position: 'absolute',
            }}
          >
            <Header />
            <Box sx={{ mt: 12 }}>
              <Fade top duration={1000}>
                <Typography sx={Styles.headHeading}>
                  Having Questions About Us ?
                </Typography>
              </Fade>
              <Zoom duration={1000}>
                <Typography sx={{ ...Styles.subheading, fontSize: '12px' }}>
                  We have just the &nbsp;
                  <span style={{ color: '#68858C', fontWeight: 400 }}>
                    right answers for you.
                  </span>
                </Typography>
              </Zoom>
              <Box sx={{ textAlign: 'center', mt: 1.5 }}>
                <Input
                  id="standard-basic"
                  variant="outlined"
                  disableUnderline
                  placeholder="Search articles (ex: reset passwords, changing id. etc.)"
                  sx={{
                    ...Styles.InputFieldDes,
                    border: 'none',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    // boxShadow: '10px 10px 60px #bebebe',
                    height: {
                      xl: '35px',
                      lg: '35px',
                      md: '30px',
                      sm: '25px',
                      xs: '25px',
                    },
                    borderRadius: '10px',
                    width: {
                      xl: '550px',
                      lg: '550px',
                      md: '420px',
                      sm: '300px',
                      xs: '300px',
                    },
                  }}
                  //   onFocus={handleInputFocusInput}
                  //   onBlur={handleInputBlurInput}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        src={searchimg}
                        sx={{ height: 'auto', width: '25px' }}
                      />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      color: '#797979',
                      fontSize: '14px',
                      padding: '7px',
                      paddingLeft: '10px', // Adjust the left padding to make room for the icon
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            width: '95%',
            gap: '10px',
            mx: 'auto',
            mt: 2,
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(230, 233, 245, 1)',
              borderRadius: '10px',
              maxHeight: 'auto',
              minWidth: '330px',
              maxWidth: '230px',
              p: 2,
            }}
          >
            <Typography
              sx={{
                ...Styles.gridheaderText,
                color: 'rgba(62, 50, 50, 1)',
                textAlign: 'left',
                fontWeight: 700,
                ml: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
              }}
            >
              Categories
            </Typography>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 0 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 0 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(0)}
            >
              <Typography>General</Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 1 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 1 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(1)}
            >
              <Typography>Services and Features</Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 2 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 2 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(2)}
            >
              <Typography>Payment and Billing</Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 3 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 3 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(3)}
            >
              <Typography>Technical Issues</Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 4 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 4 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(4)}
            >
              <Typography>Privacy and Security</Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                ...Styles.categoriesBox,
                background: tab === 5 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                color:
                  tab === 5 ? 'rgba(255, 255, 255, 1)' : 'rgba(62, 50, 50, 1)',
              }}
              onClick={() => setTab(5)}
            >
              <Typography>Account and Subscription </Typography>
              <Box
                component={'img'}
                src={rightarr}
                alt=""
                sx={{ height: 'auto', width: 'auto' }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid rgba(230, 233, 245, 1)',
              borderRadius: '10px',
              width: '900px',
              height: 'auto',
              px: 3,
            }}
          >
            <Typography
              sx={{
                ...Styles.gridheaderText,
                color: 'rgba(62, 50, 50, 1)',
                textAlign: 'left',
                fontWeight: 700,
                mt: 1,
              }}
            >
              FAQs
            </Typography>
            {tab === 0 ? (
              <Box
              // sx={{
              //   display: 'flex',
              //   flexDirection: 'column',
              //   alignItems: 'center',
              //   justifyContent: 'center',
              //   gap: '20px',
              //   height: '80%',
              // }}
              >
                {tabZero?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </Box>
            ) : null}
            {tab === 1 ? (
              <>
                {tabOne?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : null}
            {tab === 2 ? (
              <>
                {tabTwo?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : null}
            {tab === 3 ? (
              <>
                {tabThree?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : null}
            {tab === 4 ? (
              <>
                {tabFour?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : null}
            {tab === 5 ? (
              <>
                {tabFive?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography>{item?.question}</Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={Styles.summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : null}
          </Box>
        </Box> */}
        <Grid
          container
          rowSpacing={2}
          sx={{
            width: '90%',
            mx: 'auto',
            mt: 2,
          }}
        >
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Box
              sx={{
                border: '1px solid rgba(230, 233, 245, 1)',
                borderRadius: '10px',
                maxHeight: 'auto',
                minWidth: {
                  xl: '330px',
                  lg: '330px',
                  md: '300px',
                  sm: '300px',
                  xs: '300px',
                },
                maxWidth: '230px',
                p: 2,
              }}
            >
              <Typography
                sx={{
                  ...Styles.gridheaderText,
                  color: 'rgba(62, 50, 50, 1)',
                  textAlign: 'left',
                  fontWeight: 700,
                  ml: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
                }}
              >
                Categories
              </Typography>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 0 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 0
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(0)}
              >
                <Typography>General</Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 1 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 1
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(1)}
              >
                <Typography>Services and Features</Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 2 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 2
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(2)}
              >
                <Typography>Payment and Billing</Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 3 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 3
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(3)}
              >
                <Typography>Technical Issues</Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 4 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 4
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(4)}
              >
                <Typography>Privacy and Security</Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                sx={{
                  ...Styles.categoriesBox,
                  background: tab === 5 ? '#091A32' : 'rgba(247, 249, 251, 1)',
                  color:
                    tab === 5
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(62, 50, 50, 1)',
                }}
                onClick={() => setTab(5)}
              >
                <Typography>Account and Subscription </Typography>
                <Box
                  component={'img'}
                  src={rightarr}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xl={0.5} lg={0.5} md={1} sm={12} xs={12}></Grid>
          <Grid
            item
            xl={8.5}
            lg={8.5}
            md={8}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                border: '1px solid rgba(230, 233, 245, 1)',
                borderRadius: '10px',
                width: {
                  xl: '900px',
                  lg: '700px',
                  md: '500px',
                  sm: 'auto',
                  xs: 'auto',
                },
                height: 'auto',
                px: 3,
              }}
            >
              <Typography
                sx={{
                  ...Styles.gridheaderText,
                  color: 'rgba(62, 50, 50, 1)',
                  textAlign: 'left',
                  fontWeight: 700,
                  mt: 1,
                }}
              >
                FAQs
              </Typography>
              {tab === 0 ? (
                <Box>
                  {tabZero?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </Box>
              ) : null}
              {tab === 1 ? (
                <>
                  {tabOne?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : null}
              {tab === 2 ? (
                <>
                  {tabTwo?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : null}
              {tab === 3 ? (
                <>
                  {tabThree?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : null}
              {tab === 4 ? (
                <>
                  {tabFour?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : null}
              {tab === 5 ? (
                <>
                  {tabFive?.map((item, index) => (
                    <div key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '95%',
                          mt: 0.5,
                          py: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography>{item?.question}</Typography>
                        <Box
                          className="accordion-title"
                          onClick={() => onTitleClick(index)}
                          mt={1}
                          sx={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '20px',
                          }}
                        >
                          {index === activeIndex ? '-' : '+'}
                        </Box>
                      </Box>
                      <AccordionContent
                        className={index === activeIndex ? 'open' : ''}
                      >
                        <Typography sx={Styles.summerysubText}>
                          {item?.answer}
                        </Typography>
                      </AccordionContent>
                      <Box
                        sx={{
                          borderBottom: '1px solid rgba(230, 233, 245, 1)',
                          py: 0.5,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            background: 'rgba(3, 2, 41, 1)',
            width: '90%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 1.5,
            borderRadius: '10px',
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: {
                xl: '90%',
                lg: '90%',
                md: '90%',
                sm: '70%',
                xs: '70%',
              },
              mx: 'auto',
            }}
          >
            <Typography
              sx={{
                ...Styles.gridheaderText,
                color: 'rgba(255, 255, 255, 1)',
                textAlign: 'left',
                fontWeight: 500,
              }}
            >
              Still Have Questions ?
            </Typography>
            <Typography
              sx={{
                ...Styles.summerysubText,
                fontSize: {
                  xl: '14px',
                  lg: '14px',
                  md: '14px',
                  sm: '9px',
                  xs: '9px',
                },
                color: 'rgba(255, 255, 255, 1)',
                textAlign: 'left',
                fontWeight: 500,
              }}
            >
              Can’t find the answer you are looking for ? Please chat to our
              friendly team.
            </Typography>
          </Box>
          <Box sx={{ mr: { xl: 1.5, lg: 1.5, md: 1.5, sm: 1, xs: 1 } }}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  ...Styles.getintouchbtn,
                  background: '#68858C',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get in touch &nbsp; &nbsp;
                <Box
                  component={'img'}
                  src={rightarrwhite}
                  alt=""
                  sx={{ height: 'auto', width: 'auto' }}
                />
              </Box>
            </Link>
          </Box>
        </Box>
      </Paper>
      <Footer />
    </>
  );
};

export default FaqPage;

const AccordionContent = styled(Box)(({ theme }) => ({
  maxHeight: 0,
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-out',
  '&.open': {
    maxHeight: '500px', // Adjust this value based on your content
    transition: 'max-height 0.3s ease-in',
  },
}));
