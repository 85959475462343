import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  Avatar,
  Tooltip,
  MenuItem,
  Menu,
  IconButton,
  Divider,
  Modal,
  // Fade
} from '@mui/material';
import paralogo from '../../Assets/Header/paralogo.svg';
import mylogout from '../../Assets/Header/mylogout.svg';
import myprofile from '../../Assets/Header/myprofile.svg';
import mychat from '../../Assets/Header/mychat.svg';
import Home from '../../Assets/Header/Home.png';
import menuHorz from '../../Assets/Header/menuHorz.png';
import phone from '../../Assets/Header/phone.png';
import help from '../../Assets/Header/help.png';
import pricetag from '../../Assets/Header/pricetag.png';
import setting from '../../Assets/Header/setting.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  useGetUsersSinglePdfs,
  useGetUsersPdfs,
  useDeleteChat,
} from '../../Hooks/FileUpload/fileHooks.js';
import { useGetNewChatList } from '../../Hooks/chatHooks';
import {
  stringAvatar,
  stringToColor,
} from '../../Components/avatar/stringAvatar';
import {
  useGetUserProfile,
  useUpdateUserProfile,
} from '../../Hooks/ProfileHooks';
import cookie from 'js-cookie';
import zIndex from '@mui/material/styles/zIndex.js';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
const Header = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('Home');
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleOpenMenu = () => setOpenMenu(true);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleItemClick = (item, path) => {
    setSelectedItem(item);
    navigate(path);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();

  React.useEffect(() => {
    // Update selectedItem based on the current path
    const path = location.pathname;
    switch (path) {
      case '/':
        setSelectedItem('Home');
        break;
      case '/content':
        setSelectedItem('content');
        break;
      case '/services':
        setSelectedItem('services');
        break;
      case '/plan':
        setSelectedItem('Pricing');
        break;
      case '/faq':
        setSelectedItem('FAQ');
        break;
      case '/contact':
        setSelectedItem('Contact');
        break;
      case '/privacypolicy':
        setSelectedItem('PrivacyPolicy');
        break;
      case '/termsandcondition':
        setSelectedItem('Termsandcondition');
        break;
      case '/about':
        setSelectedItem('AboutParasors');
        break;
      default:
        // Keep current selection if path is not recognized
        break;
    }
  }, [location]);
  const headerStyle = {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    transition: 'right 2s',
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    width: '100%',
    mx: 'auto',
    position: 'absolute',
    top: 0,
    zIndex: 1,
  };

  const iconStyle = {
    display: 'block',
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    cursor: 'pointer',
  };

  const navStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: '5rem',
    right: isMenuOpen ? '0' : '100%',
    backgroundColor: '#fff',
    width: '100%',
    transition: 'right 0.3s',
    height: '85vh',
    zIndex: 1,
  };

  // Get Users Profile Data
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  const getToken = () => {
    return cookie.get('Bearer');
  };

  //  Menu option for login user

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    data: fileUploadListSingle,
    isLoading: fileUploadListSingleLoading,
    refetch: refetchFileUploadListSingle,
  } = useGetUsersSinglePdfs();

  const {
    data: newChatList,
    isLoading: newChatListLoading,
    error: newChatListError,
    refetch: newChatListRefetch,
  } = useGetNewChatList();

  async function openEmail(e) {
    window.location.href = 'mailto:business@parasors.in';
  }

  return (
    <>
      <Paper elevation={0} sx={{}}>
        <Box sx={mainHeaderStyles}>
          <Flip top duration={1000}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ p: 0, ml: 2 }}>
                <Box
                  component={'img'}
                  src={paralogo}
                  alt=""
                  onClick={() => {
                    navigate('/');
                  }}
                  sx={{
                    height: 'auto',
                    width: '60%',
                    cursor: 'pointer',
                    m: 1,
                    transition: '0.3s',
                    transform: 'scale(1)',
                    display: {
                      xl: 'flex',
                      lg: 'flex',
                      md: 'flex',
                      sm: 'flex',
                      xs: 'none',
                    },
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = 'scale(1.1)';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                />
              </Box>
              <Typography
                sx={{
                  color: '#081930',
                  fontFamily: 'monospace',
                  fontSize: '16px',
                  fontWeight: 600,
                  letterSpacing: '-.1.1rem',
                }}
              >
                Para
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: { xl: 5, lg: 5, md: 4, sm: 4, xs: 0 },
              }}
            >
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom: selectedItem === 'Home' ? '2px solid' : 'none',
                  color: selectedItem === 'Home' ? 'primary.main' : '#000',
                }}
                onClick={() => handleItemClick('Home', '/')}
              >
                Home
              </Box>
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom:
                    selectedItem === 'services' ? '2px solid' : 'none',
                  color: selectedItem === 'services' ? 'primary.main' : '#000',
                }}
                onClick={() => {
                  handleItemClick('services', '/services');
                }}
              >
                Services
              </Box>
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom:
                    selectedItem === 'Pricing' ? '2px solid' : 'none',
                  color: selectedItem === 'Pricing' ? 'primary.main' : '#000',
                }}
                onClick={() => {
                  handleItemClick('Pricing', '/plan');
                }}
              >
                Pricing
              </Box>
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom: selectedItem === 'FAQ' ? '2px solid' : 'none',
                  color: selectedItem === 'FAQ' ? 'primary.main' : '#000',
                }}
                onClick={() => {
                  handleItemClick('FAQ', '/faq');
                }}
              >
                FAQ
              </Box>
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom:
                    selectedItem === 'content' ? '2px solid' : 'none',
                  color: selectedItem === 'content' ? 'primary.main' : '#000',
                }}
                onClick={() => {
                  handleItemClick('content', '/content');
                }}
              >
                Content Coverage
              </Box>
              <Box
                sx={{
                  ...menuListText,
                  borderBottomColor: 'primary.main',
                  borderBottom:
                    selectedItem === 'Contact' ? '2px solid' : 'none',
                  color: selectedItem === 'Contact' ? 'primary.main' : '#000',
                }}
                onClick={() => {
                  handleItemClick('Contact', '/contact');
                  // openEmail();
                }}
              >
                Contact Us
              </Box>
            </Box>
            <Box>
              {getToken() ? null : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    p: 0.2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    onClick={() => {
                      navigate('/signup');
                    }}
                    sx={{ ...menuListText, color: '#000' }}
                  >
                    Join
                  </Typography>
                  <Button
                    variant="contained"
                    sx={login}
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    Log In
                  </Button>
                </Box>
              )}
              {getToken() ? (
                <Box sx={{ cursor: 'pointer' }}>
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        zIndex: 1,
                        transition: '0.3s',
                        transform: 'scale(1)',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      <Tooltip title="">
                        <Box onClick={handleOpenMenu}>
                          <Avatar
                            {...stringAvatar(profileData?.data?.data?.fullName)}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openMenu}
                      onClose={handleCloseMenu}
                      closeAfterTransition
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                      BackdropProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                        timeout: 500,
                      }}
                    >
                      {/* <Fade in={open}> */}
                      <Box sx={style}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        >
                          <Box sx={outerMenuList}>
                            <Box
                              sx={menuListTextProfile}
                              onClick={() => {
                                navigate(
                                  `/mainchat/${
                                    newChatList?.data?.data?.at(-1)?._id
                                  }`
                                );
                                handleClose();
                              }}
                            >
                              <Box
                                component={'img'}
                                src={mychat}
                                alt=""
                                sx={{ height: '20px', width: '20px' }}
                              />{' '}
                              My Chat
                            </Box>
                          </Box>
                          <Box sx={outerMenuList}>
                            <Box
                              sx={menuListTextProfile}
                              onClick={() => {
                                navigate('/profile');
                                handleClose();
                              }}
                            >
                              <Box
                                component={'img'}
                                src={myprofile}
                                alt=""
                                sx={{ height: '20px', width: '20px' }}
                              />
                              Profile
                            </Box>
                          </Box>
                          <Divider />
                          <Box sx={outerMenuList}>
                            <Box
                              sx={menuListTextProfile}
                              onClick={() => {
                                cookie.remove('Bearer');
                                window.location.href = '/';
                              }}
                            >
                              <Box
                                component={'img'}
                                src={mylogout}
                                alt=""
                                sx={{ height: '20px', width: '20px' }}
                              />
                              Logout
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* </Fade> */}
                    </Modal>
                  </>
                </Box>
              ) : null}
            </Box>
          </Flip>
        </Box>
        {/* mobile */}
        <Box
          sx={{
            display: {
              xl: 'none',
              lg: 'none',
              md: 'none',
              sm: 'none',
              xs: 'flex',
            },
          }}
        >
          <header style={headerStyle}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '95%',
                mx: 'Auto',
              }}
            >
              <div style={iconStyle} onClick={toggleMenu}>
                <Box
                  component={'img'}
                  src={menuHorz}
                  alt=""
                  sx={{
                    height: 'auto',
                    width: '25px',
                    transition: '0.5s',
                    transform: isMenuOpen ? 'rotate(90deg)' : null,
                  }}
                />
              </div>
              <Box sx={{ display: 'flex', ml: 'auto', mt: 2 }}>
                {getToken() ? null : (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      p: 0.2,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      onClick={() => {
                        navigate('/signup');
                      }}
                      sx={{ ...menuListText, color: '#000' }}
                    >
                      Join
                    </Typography>
                    <Button
                      sx={{ ...login, background: 'rgba(96, 91, 255, 1)' }}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Log In
                    </Button>
                  </Box>
                )}
                {getToken() ? (
                  <Box sx={{ cursor: 'pointer', mr: 1 }}>
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Tooltip title="">
                          <Box onClick={handleOpenMenu}>
                            <Avatar
                              {...stringAvatar(
                                profileData?.data?.data?.fullName
                              )}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openMenu}
                        onClose={handleCloseMenu}
                        closeAfterTransition
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                        BackdropProps={{
                          style: {
                            backgroundColor: 'transparent',
                          },
                          timeout: 500,
                        }}
                      >
                        <Box sx={style}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                            }}
                          >
                            <Box
                              sx={outerMenuList}
                              onClick={() => {
                                navigate(
                                  `/mainchat/${
                                    newChatList?.data?.data?.at(-1)?._id
                                  }`
                                );
                                handleClose();
                              }}
                            >
                              <Box sx={menuListTextProfile}>
                                <Box
                                  component={'img'}
                                  src={mychat}
                                  alt=""
                                  sx={{ height: '20px', width: '20px' }}
                                />{' '}
                                My Chat
                              </Box>
                            </Box>
                            <Box
                              sx={outerMenuList}
                              onClick={() => {
                                navigate('/profile');
                                handleClose();
                              }}
                            >
                              <Box sx={menuListTextProfile}>
                                <Box
                                  component={'img'}
                                  src={myprofile}
                                  alt=""
                                  sx={{ height: '20px', width: '20px' }}
                                />
                                Profile
                              </Box>
                            </Box>
                            <Divider />
                            <Box
                              sx={outerMenuList}
                              onClick={() => {
                                cookie.remove('Bearer');
                                window.location.href = '/';
                              }}
                            >
                              <Box sx={menuListTextProfile}>
                                <Box
                                  component={'img'}
                                  src={mylogout}
                                  alt=""
                                  sx={{ height: '20px', width: '20px' }}
                                />
                                Logout
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </header>
          <nav style={navStyle}>
            <ul>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  mx: 'auto',
                  gap: '40px',
                  flexDirection: 'column',
                }}
              >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Home</Typography>
                  </Box>
                </Link>
                <Link to="/about" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>About Us</Typography>
                  </Box>
                </Link>
                <Link to="/services" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Services</Typography>
                  </Box>
                </Link>
                <Link to="/plan" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Pricing</Typography>
                  </Box>
                </Link>
                <Link to="/faq" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>FAQs</Typography>
                  </Box>
                </Link>
                <Link to="/content" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>
                      Content Coverage
                    </Typography>
                  </Box>
                </Link>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <Box sx={adjHeaderTextStyle}>
                    <Typography sx={headerTextStyle}>Contact</Typography>
                  </Box>
                </Link>
              </Box>
            </ul>
          </nav>
        </Box>
      </Paper>
    </>
  );
};

export default Header;
const style = {
  position: 'absolute',
  top: '9%',
  right: '1%',
  // transform: "translate(-50%, -50%)",
  width: 150,
  // boxShadow: '0px 1px 3px #00000029',
  background: '#fff',
  borderRadius: '15px',
  border: '2px solid rgba(241, 243, 246, 1)',
  p: 1,
  zIndex: 999999,
};

const outerMenuList = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '&:hover': {
    background: 'rgba(241, 243, 246, 1)',
    borderRadius: '10px',
    cursor: 'pointer',
  },
};

const menuListText = {
  color: '#000',
  fontFamily: 'Segoe UI',
  fontSize: { xl: '18px', lg: '16px', md: '14px', sm: '12px', xs: '12px' },
  fontStyle: 'normal',
  fontWeight: 400,
  cursor: 'pointer',
  '&:hover': {
    // fontWeight: 500,
    color: 'primary.main',
  },
};
const menuListTextProfile = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  alignItems: 'center',
  transition: 'border-color 0.5s ease-in-out',
  p: 1,

  color: '#191919',
  fontFamily: 'Manrope',
  fontSize: { xl: '15px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontStyle: 'normal',
  fontWeight: 400,
  cursor: 'pointer',
};
const startBtn = {
  borderRadius: { xl: '30px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
  background: '#FFF',
  color: '#009FFC',
  textAlign: 'center',
  fontFamily: 'Segoe UI',
  fontSize: { xl: '14px', lg: '12px', md: '10px', sm: '8px', xs: '10px' },
  fontStyle: 'normal',
  fontWeight: 600,
  p: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
  width: { xl: '140px', lg: '140px', md: '120px', sm: '100px', xs: '80px' },
  textTransform: 'none',
  mr: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 },
};
const login = {
  color: '#fff',
  fontFamily: 'Segoe UI',
  fontSize: { xl: '18px', lg: '16px', md: '14px', sm: '12px', xs: '10px' },
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: { xl: '22px', lg: '22px', md: '22px', sm: '20px', xs: '18px' },
  textTransform: 'none',
  borderRadius: '20px',
  '&:hover': {
    borderRadius: '20px',
    background: 'transparent',
    color: 'primary.main',
  },
};
const headerTextStyle = {
  color: '#191919',
  fontFamily: 'Lato',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'left',
};
const mainHeaderStyles = {
  display: {
    xl: 'flex',
    lg: 'flex',
    md: 'flex',
    sm: 'flex',
    xs: 'none',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '10vh',
  backgroundColor: 'transparent',
  color: 'white',
  fontSize: '30px',
  fontWeight: 'bold',
  fontFamily: 'Lato',
  width: '98%',
  mx: 'auto',
  position: 'absolute',
  top: 0,
  zIndex: 1,
};

const adjHeaderTextStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
};
