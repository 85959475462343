import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { GetTokenFunc } from '../utils/GetToken';

// Get Comments By Id in Chat Room
export const useGetUsersComment = (id) => {
  return useQuery({
    queryKey: ['chat-comment', id],
    queryFn: async () => {
      if (!id) return;
      return await axios.get(`/api/v1/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

// Post/Create Comments By Id in Chat Room
export const useCreateComment = () => {
  return useMutation(async (data) => {
    return await axios.post('/api/v1/comments', data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
  });
};

// Delete Comments By Id in Chat Room
export const useDeleteComment = () => {
  return useMutation(async (id) => {
    return await axios.delete(`/api/v1/comments/${id}`, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
  });
};

export const useGetPdf = (chatId) => {
  console.log('chatId', chatId);
  console.log('hello', chatId);
  return useQuery({
    queryKey: ['pdf', chatId?.fileData?.[0]?.fileUrl],
    queryFn: async () => {
      try {
        const lastPartOfUrl = chatId?.fileData?.[0]?.fileUrl?.split('/').pop();
        if (lastPartOfUrl) {
          const response = await axios.get(`pdf/${lastPartOfUrl}`, {
            responseType: 'arraybuffer',
          });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          return url;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    enabled: !!chatId?.fileData?.[0]?.fileUrl,
  });
};
// multiple
export const useGetMultiplePdf = (chatId) => {
  console.log('chatId', chatId);
  return useQuery({
    queryKey: ['pdf', chatId?.fileData?.[0]?.fileUrl],
    queryFn: async () => {
      try {
        const lastPartOfUrl = chatId?.fileData?.[0]?.fileUrl?.split('/').pop();
        if (lastPartOfUrl) {
          const response = await axios.get(`pdf/${lastPartOfUrl}`, {
            responseType: 'arraybuffer',
          });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          return url;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    enabled: !!chatId?.fileData?.[0]?.fileUrl,
  });
};
