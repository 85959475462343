import React, { useRef } from 'react';
import { Paper, Box, Typography, Skeleton } from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import { useGetArticals } from '../../Hooks/blogHooks.js';
import forwardicon from '../../Assets/mainPage/forwardicon.svg';
import backwardicon from '../../Assets/mainPage/backwardicon.svg';
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';

const OpinionComponent = () => {
  const scrollBoxRef = useRef(null);
  const navigate = useNavigate();
  const { data: articals, isLoading: articleLoading } = useGetArticals();

  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' });
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          background: 'rgba(247, 249, 251, 1)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 10,
          p: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '80%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Fade top duration={500}>
            <Typography sx={Styles.boxMainText}>Latest Opinion</Typography>
          </Fade>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Box
              component={'img'}
              src={backwardicon}
              sx={scrollBtns}
              onClick={scrollBackward}
            />
            <Box
              component={'img'}
              src={forwardicon}
              sx={scrollBtns}
              onClick={scrollForward}
            />
          </Box>
        </Box>

        {/* Content Area */}
        <Box sx={scrollingImg} ref={scrollBoxRef}>
          {articleLoading ? (
            <>
              {[...Array(2)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  sx={{
                    height: {
                      xl: '380px',
                      lg: '380px',
                      md: '340px',
                      sm: '210px',
                      xs: '210px',
                    },
                    width: '600px',
                    borderRadius: '10px',
                    ml: 1,
                  }}
                />
              ))}
            </>
          ) : (
            articals?.data?.map((item, index) => (
              <Fade top duration={500} key={index}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(`/viewnews`, {
                      state: {
                        title: item?.title,
                        overView: item?.overView,
                        image: item?.image,
                        analysis: item?.analysis,
                        keyInsights: item?.keyInsights,
                      },
                    });
                  }}
                >
                  <Box
                    component={'img'}
                    src={item?.image}
                    alt=""
                    sx={bigimageStyles}
                  />
                  <Box sx={newsBoxText}>
                    <Typography
                      sx={{
                        ...Styles.boxMainText,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 1,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.boxSubText,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.overView?.replace(/<\/?[^>]+(>|$)/g, '')}
                    </Typography>
                    <Typography
                      sx={{
                        ...Styles.boxSubText,
                        fontSize: '16px',
                      }}
                    >
                      {new Date(item.createdAt).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}{' '}
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            ))
          )}
        </Box>
      </Box>
    </div>
  );
};

export default OpinionComponent;

const scrollingImg = {
  mt: 2,
  height: 'auto',
  width: '100%',
  mx: 'auto',
  maxWidth: { xl: '80%', lg: '80%', md: '80%', sm: '95%', xs: '95%' },
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: { xl: '20px', lg: '20px', md: '20px', sm: '10px', xs: '10px' },
  whiteSpace: 'nowrap',
};

const bigimageStyles = {
  maxHeight: {
    xl: '380px',
    lg: '380px',
    md: '340px',
    sm: '210px',
    xs: '210px',
  },
  ml: 1,
  Width: 'auto',
  height: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.01)',
  },
};

const newsBoxText = {
  backgroundColor: '#E8E8E7',
  position: 'absolute',
  bottom: '25px',
  left: '10px',
  color: '#000',
  padding: '10px 10px',
  borderRadius: '10px',
  width: { xl: '95%', lg: '95%', md: '95%', sm: '91%', xs: '91%' },
  mx: 'auto',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  opacity: 0.9,
};

const scrollBtns = {
  height: 'auto',
  width: 'auto',
  cursor: 'pointer',
  boxShadow: 3,
  borderRadius: '10px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: 6,
  },
};
